import axios from 'axios';
import endpoints from './endpoints';
import LocalStorageService from "./localStorageService";

const localStorageService = LocalStorageService.getService();
const axiosApiInstance = axios.create();

axiosApiInstance.interceptors.request.use(
    async config => {
        config.headers = {
            'Authorization': `Bearer ${localStorageService.getAccessToken()}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
        return config;
    },
    error => {
        Promise.reject(error)
    });


axiosApiInstance.interceptors.response.use((response) => {
    return response
}, async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        return axios.post(endpoints.backend.refresh, {
            "refresh": localStorageService.getRefreshToken() // Que tyengo almacenado
        }).then(res => {
            if (res.status === 201 || res.status === 200) {
                localStorageService.setToken(res.data.access);
                axiosApiInstance.defaults.headers.common['Authorization'] = 'Bearer ' + localStorageService.getAccessToken();
                return axiosApiInstance(originalRequest);
            }
        }).catch((err) => {
            window.location.href = endpoints.rootLogin;
        });
    }
    return Promise.reject(error);
});

export default axiosApiInstance;