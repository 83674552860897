import axiosApiInstance from "./axios.interceptor";
import endpoints from "./endpoints";

class ComunasService {

    constructor() {
        if (!!ComunasService.instance) {
            return ComunasService.instance;
        }
        this.ComunasTodas = []
        this.__get_comunas().then(response => {
            this.ComunasTodas = response.data
        });
        ComunasService.instance = this;
        this.config = {}
    }

    isReady() {
        return this.ComunasTodas.length > 0
    }

    __get_comunas() {
        return axiosApiInstance.get(endpoints.backend.getcomunas, this.config);
    }

    GetComunas = (region_id) => {
        if (this.ComunasTodas && this.ComunasTodas.length > 0) {
            return this.ComunasTodas.filter(comuna => Number(comuna.codigo_region) === Number(region_id))
        }
    }
}

export default ComunasService;
