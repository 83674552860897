export const base = "api";
const root = "";

const endpoints = {
  backend: {
    home: `${root}/${base}/home/proyectos/`,
    eistuStepOne: `${root}/${base}/guardar_eistu/PASO1/`,
    eistuSinModificacion: `${root}/${base}/guardar_eistu/SINMODIFICACION/`,
    eistuStepLoteo: `${root}/${base}/guardar_eistu/LOTEO/`,
    eistuStepTwo: `${root}/${base}/guardar_eistu/PASO2/`,
    eistuAmpliacion: `${root}/${base}/guardar_eistu/AMPLIACION/`,
    eistuAccesos: `${root}/${base}/guardar_eistu/ACCESOS/`,
    eistuFichaMitigaciones: `${root}/${base}/guardar_eistu/MITIGACIONES/`,
    eistuFichaAccesos: `${root}/${base}/guardar_eistu/FICHA_ACCESOS/`,
    eistuExempt: `${root}/${base}/eistu/`,
    eistuExemptSinMod: `${root}/${base}/eistu_sin_mod/`,
    enterprise: `${root}/${base}/empresa`,
    createEnterprise: `${root}/${base}/empresa/`,
    via: `${root}/${base}/direccion_vias`,
    tipoAcceso: `${root}/${base}/tipo_acceso/`,
    prediosResultantesInit: `${root}/${base}/proyecto/destinos/`,
    project: `${root}/${base}/proyectos_en_proceso`,
    projectsimple: `${root}/${base}/proyectos_categorizados`,
    proyectosCategorizados: `${root}/${base}/proyectos_categorizados`,
    proyectosBorrador: `${root}/${base}/proyectos_en_borrador`,
    createProject: `${root}/${base}/proyecto`,
    detalleProyecto: `${root}/${base}/proyecto`,
    user: `${root}/${base}/users`,
    vehicle: `${root}/${base}/vehiculos_equivalente`,
    perfil: `${root}/${base}/perfil/`,
    usuario: `${root}/${base}/usuario/`,
    areaTypes: `${root}/${base}/tipos_area`,
    arteryTypes: `${root}/${base}/tipos_arteria`,
    getloteosaprobados: `${root}/${base}/busqueda_loteos/`,
    refresh: `${root}/${base}/token/refresh/`,
    obtenertoken: `${root}/${base}/obtenertoken/`,
    categorize: `${root}/${base}/categorizar`,
    devgetJointProjects: `${root}/${base}/dev-proyectos-conjuntos`,
    getJointProjects: `${root}/${base}/proyectos-conjuntos`,
    getJointProjectDetails: `${root}/${base}/proyectos-conjuntos-detalle`,
    createjointproject: `${root}/${base}/crear-proyecto-conjunto`,
    proyectossearch: `${root}/${base}/proyectos-search`,
    proyectossearchexternos: `${root}/${base}/proyectos-search-externos`,
    getregiones: `${root}/${base}/regiones/`,
    getcomunas: `${root}/${base}/todas-las-comunas`,
    destino_proyecto: `${root}/${base}/destino_proyecto/`,

    representant: `${root}/${base}/persona_juridica/representantes/`,
    createConsultant: `${root}/${base}/representante/`,
    participationType: `${root}/${base}/tipo_participacion/profesionales/`,

    pdfOficioConjunto: `${root}/${base}/pdf_proyecto_conjunto_oficio`,
    pdfOficioSimple: `${root}/${base}/pdf_proyecto_simple_oficio`,
    pdfCertram: `${root}/${base}/pdf_certram`,

    pdfSimulacionConjunto: `${root}/${base}/pdf_proyecto_conjunto_simulacion`,
    pdfSimulacionSimple: `${root}/${base}/pdf_proyecto_simple_simulacion`,
    declaracionesPendientes: `${root}/${base}/declaraciones_pendientes`,
    declaracionProyectoConjunto: `${root}/${base}/declaracion_proyecto_conjunto`,
    categorizarConjunto: `${root}/${base}/categorizar_conjunto`,

    setProyectoConjuntoDefinitivo: `${root}/${base}/set_proyecto_conjunto_definitivo`,
    setProyectoConjuntoSimulacion: `${root}/${base}/set_proyecto_conjunto_simulacion`,

    setProyectoSimulacion: `${root}/${base}/set_proyecto_simulacion`,
    setProyectoDefinitivo: `${root}/${base}/set_proyecto_definitivo`,

    proyectosCategorizadosDetalle: `${root}/${base}/proyectos_categorizados/detalle/`,
    profesionalAutorizadoLista: `${root}/${base}/profesional_autorizado/lista/`,
    tipoParticipacionProfesionales: `${root}/${base}/tipo_participacion/profesionales/`,

    profesionalAutorizado: `${root}/${base}/profesional_autorizado/`,

    uploadfile: `${root}/upload/files/`,

    imiv: `${root}/${base}/imiv`,
    aporte_espacio_publico: `${root}/${base}/aporte_espacio_publico`,
    desistir_evaluacion_eaep: `${root}/${base}/desistir_evaluacion_eaep/`,
    listaIngresoImiv: `${root}/${base}/lista_ingreso_imiv`,
    ingresarImiv: `${root}/${base}/ingresar_imiv/`,
    antecedentesCategoria: `${root}/${base}/antecedentes_categoria`,

    fichaConjunto: `${root}/${base}/ficha_conjunto/`,
    listaProcesoEvaluacion: `${root}/${base}/lista_proceso_evaluacion/`,
    datosCategorizacionProyecto: `${root}/${base}/datos_categorizacion_proyecto/`,
    historial: `${root}/${base}/historial/`,
    tipoAntecedentesFicha: `${root}/${base}/tipo_antecedentes_ficha/`,
    documentos: `${root}/${base}/documentos/`,
    antecedentesFicha: `${root}/${base}/antecedentes_ficha/`,
    resubirAntecedentes: `${root}/${base}/resubir_antecedentes/`,
    desistirEvaluacion: `${root}/${base}/desistir_evaluacion/`,
    solicitarCertificadoSilencioPositivo: `${root}/${base}/solicitar_certificado_silencio_positivo/`,
    prorrogas: `${root}/${base}/prorrogas/`,
    prorrogasSolicitar: `${root}/${base}/prorrogas/solicitar/`,
    prorrogasEAEPSolicitar: `${root}/${base}/prorrogas_eaep/solicitar/`,
    prorrogas_eaep: `${root}/${base}/prorrogas_eaep`,
    evaluacionImiv: `${root}/${base}/evaluacionImiv/`,
    
    representantes: `${root}/${base}/representantes/`,
    manejo_representacion: `${root}/${base}/manejo_representacion/`,
    proyectoTipoTramite: `${root}/${base}/proyectos_tipo_tramite`,
    seccionTramitarModificacion: `${root}/${base}/seccion_tramitar_proyecto_modificacion`,
    tramitarProyectoModificacion: `${root}/${base}/tramitar_proyecto_modificacion/`,
    editarProyectoModificacion: `${root}/${base}/editar_proyecto/`,
    tipoTramite: `${root}/${base}/tipo_tramite/`,
    normaUrbanisticaAgregar: `${root}/${base}/norma_urbanistica/agregar/0/`,
    borrarProyecto: `${root}/${base}/borrar_ficha/`,
    get_folio_seim: `${root}/${base}/get_folio_seim/`,
    obtenerEmpresa: `${root}/${base}/empresa`,
    historial_eaep: `${root}/${base}/historial_eaep/`,
    declaracionJurada: `${root}/${base}/datapdf/declaracion_jurada/`,
    validacionCup: `${root}/${base}/validarcup/`,
    verificaciones: `${root}/${base}/verifica/`,
    verificaFirmante: `${root}/${base}/verifica_firmante/`,
    obtenerPersonaJuridica: `${root}/${base}/persona_juridica`,
    obtenerPersonaJuridicaObservados: `${root}/${base}/persona_juridica_observados`,
    obtenerLandingPage: `${root}/${base}/obtenerlandingpage/`,
  },
  // PROXY PARAMS
  root: "/",
  rootLogin: "/signin",
  signin: "/openid-signin",
  signout: "/openid-signout",
};

export default endpoints;
