import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import asyncComponent from "util/asyncComponent";


const ProyectoInmobiliario = ({ match }) => (
    <div className="app-wrapper">
        <Switch>
            <Redirect exact from={`${match.url}/`} to={`${match.url}/preliminares`}/>
            <Route path={`${match.url}/preliminares`} component={asyncComponent(() => import("./routes/preliminares"))}/>
            <Route path={`${match.url}/crear-proyecto`} component={asyncComponent(() => import("./routes/crear-proyecto"))}/>
            <Route path={`${match.url}/menu-modificacion`} component={asyncComponent(() => import("./routes/editar-proyecto"))}/>
            <Route path={`${match.url}/editar-proyecto-simple`} component={asyncComponent(() => import("./routes/editar-proyecto/EditarProyectoSimple"))}/>
            <Route path={`${match.url}/editar-proyecto-conjunto`} component={asyncComponent(() => import("./routes/editar-proyecto/EditarProyectoConjunto"))}/>
            <Route path={`${match.url}/proyecto-conjunto`} component={asyncComponent(() => import("./routes/proyecto-conjunto"))}/>
            <Route path={`${match.url}/proyecto-simple`} component={asyncComponent(() => import("./routes/proyecto-simple"))}/>
            <Route path={`${match.url}/ingresar-imiv`} component={asyncComponent(() => import("./routes/ingresar-imiv/index"))}/>
            <Route path={`${match.url}/modificar-eistu`} component={asyncComponent(() => import("./routes/modificacion-eistu/index"))}/>
            <Route path={`${match.url}/ficha-original-nueva`} component={asyncComponent(() => import("./routes/modificacion-eistu/acciones/fichaOriginalNueva"))}/>
            <Route path={`${match.url}/modificar-accesos-excento`} component={asyncComponent(() => import("./routes/modificacion-eistu/acciones/accesosProyecto"))}/>
            <Route path={`${match.url}/ficha-proyecto-acceso`} component={asyncComponent(() => import("./routes/modificacion-eistu/acciones/fichaProyectoAcceso"))}/>
            <Route path={`${match.url}/ficha-loteo-predio`} component={asyncComponent(() => import("./routes/modificacion-eistu/acciones/fichaLoteoPredio"))}/>
            <Route path={`${match.url}/ficha-medidas-mitigacion`} component={asyncComponent(() => import("./routes/modificacion-eistu/acciones/fichaMitigaciones"))}/>
            <Route path={`${match.url}/ficha-normal`} component={asyncComponent(() => import("./routes/modificacion-eistu/acciones/fichaNormal"))}/>
            <Route path={`${match.url}/ficha-original-nueva-acceso`} component={asyncComponent(() => import("./routes/modificacion-eistu/acciones/fichaOriginalNuevaAcceso"))}/>
        </Switch>
    </div>
);
export default ProyectoInmobiliario;
