import React from 'react';
import ReactDOM from 'react-dom';
import './assets/fonts/Roboto/Roboto-Black.ttf'
import './assets/fonts/Roboto/Roboto-BlackItalic.ttf'
import './assets/fonts/Roboto/Roboto-Bold.ttf'
import './assets/fonts/Roboto/Roboto-BoldItalic.ttf'
import './assets/fonts/Roboto/Roboto-Italic.ttf'
import './assets/fonts/Roboto/Roboto-Light.ttf'
import './assets/fonts/Roboto/Roboto-LightItalic.ttf'
import './assets/fonts/Roboto/Roboto-Medium.ttf'
import './assets/fonts/Roboto/Roboto-MediumItalic.ttf'
import './assets/fonts/Roboto/Roboto-Regular.ttf'
import './assets/fonts/Roboto/Roboto-Thin.ttf'
import './assets/fonts/Roboto/Roboto-ThinItalic.ttf'

const rootEl = document.getElementById('app-site');


// Create a reusable render method that we can call more than once
let render = (_) => {
	// Dynamically import our main App component, and render it
	const MainApp = require('./MainApp').default;
	ReactDOM.render(
			<MainApp />,
		rootEl
	);
};

if (module.hot) {
	module.hot.accept('./MainApp', () => {
		const MainApp = require('./MainApp').default;
		render(
			<MainApp />,
			rootEl
		);
	});
}

render();
