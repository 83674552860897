import {
    atom
} from 'recoil';

export const alertAtom = atom({
    key: 'alertAtom', // unique ID (with respect to other atoms/selectors)
    default: '', // default value (aka initial value)
});

/**const charCountState = selector({
    key: 'charCountState', // unique ID (with respect to other atoms/selectors)
    get: ({ get }) => {
        const text = get(textState);

        return text.length;
    },
}); */

export const titleAlertAtom = atom({
    key: 'titleAlertAtom', // unique ID (with respect to other atoms/selectors)
    default: '', // default value (aka initial value)
});

export const messageAlertAtom = atom({
    key: 'messageAlertAtom', // unique ID (with respect to other atoms/selectors)
    default: '', // default value (aka initial value)
});

export const dialogAtom = atom({
    key: 'dialogAtom', // unique ID (with respect to other atoms/selectors)
    default: {
        open: false,
        title: "Procesando solicitud, por favor espere..."
    }, // default value (aka initial value)
});


