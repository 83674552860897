import React, { useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert'
import SaveAltIcon from '@material-ui/icons/SaveAlt';

const DangerAlert = ({ dismiss, title, message }) => {
    const [show, setShow] = useState(true);
    const onConfirm = () => {
        setShow(false);
        dismiss(true);
    }
    return <SweetAlert show={show} danger title={title} onConfirm={onConfirm}>{message}</SweetAlert>
}

const SuccessAlert = ({ dismiss, title, message }) => {
    const [show, setShow] = useState(true);
    const onConfirm = () => {
        dismiss(true);
        setShow(false);
    }
    return <SweetAlert show={show} success title={title} onConfirm={onConfirm}> {message} </SweetAlert>
}

const WarningAlert = ({ dismiss, title, message }) => {
    const [show, setShow] = useState(true);
    const onConfirm = () => {
        dismiss(true);
        setShow(false);
    }
    return <SweetAlert show={show} warning title={title} onConfirm={onConfirm}> {message} </SweetAlert>
}

const DeleteAlert = ({ dismiss, title, message, confirm, cancelText, confirmText }) => {
    const [show, setShow] = useState(true);
    const onConfirm = () => {
        confirm(true);
        setShow(false);
    }
    const onCancel = () => {
        dismiss(true)
        setShow(false)
    }
    return <SweetAlert show={show} warning title={title} showCancel onConfirm={onConfirm} onCancel={onCancel} cancelBtnText={cancelText} confirmBtnText={confirmText} confirmBtnBsStyle="danger" > {message} </SweetAlert>
}
const DownLoadFile = ({ dismiss, title, message, confirm, cancelText, confirmText, file }) => {
    return <SweetAlert
        custom
        confirmBtnText="Aceptar"
        confirmBtnBsStyle="primary"
        title={title || "Titulo"}
        onConfirm={dismiss}
        onCancel={dismiss}
    >
        <span>Descargar certificado de exención <a href={file} download>
            <SaveAltIcon></SaveAltIcon></a> </span>
    </SweetAlert>
}

const InputDialog = ({ dismiss, title, message, confirm, cancelText, confirmText, file }) => {
    return <SweetAlert
        input
        confirmBtnText="Aceptar"
        confirmBtnBsStyle="primary"
        title={title || "Titulo"}
        placeholder="Ingrese nombre de proyecto"
        cancelBtnText="Generar"
        //cancelBtnText="Cancelar"
        onConfirm={confirm}
        onCancel={dismiss}
    >
        
    </SweetAlert>
}


function DefaultAlert(title, message) {
    const [show, setShow] = useState(false);
    const onConfirm = () => {
        setShow(false);
    }

    return <SweetAlert show={show} success title={"title"} onConfirm={onConfirm}> {"message"} </SweetAlert>
}



class AlertHelper {

    constructor() { }

    createAlert(type, onConfirm, title, message, onCancel = undefined, cancelText = undefined, confirmText = undefined, file) {
        switch (type) {
            case "danger":
                return <DangerAlert dismiss={(d) => onConfirm(d)} title={title} message={message} />;
            case "success":
                return <SuccessAlert dismiss={(d) => onConfirm(d)} title={title} message={message} />;
            case "warning":
                return <WarningAlert dismiss={(d) => onConfirm(d)} title={title} message={message} />;
            case "input":
                return <InputDialog dismiss={(d) => onCancel(d)} title={title} message={message} confirm={d => onConfirm(d)} />
            case "download":
                return <DownLoadFile dismiss={(d) => onConfirm(d)} title={title} message={message} file={file} />
            case "delete":
                if (typeof onCancel === 'undefined' || typeof cancelText === 'undefined' || typeof confirmText === 'undefined') {
                    return <></>;
                } else {
                    return <DeleteAlert dismiss={(d) => onCancel(d)} title={title} message={message}
                        confirm={(d) => onConfirm(d)} cancelText={cancelText}
                        confirmText={confirmText} />;
                }
            default:
                return <></>;
        }
    }
}
export default AlertHelper;
