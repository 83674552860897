import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import CircularProgress from "@material-ui/core/CircularProgress";
import { versionNumber } from "constants/VersionNumber";
import {
  hideMessage,
  showAuthLoader,
  userFacebookSignIn,
  userGithubSignIn,
  userGoogleSignIn,
  userSignIn,
  userTwitterSignIn,
} from "actions/Auth";

import endpoints from "services/backend/endpoints";
import { v4 as uuidv4 } from "uuid";
import { blue } from "@material-ui/core/colors";
import YouTubeIcon from '@material-ui/icons/YouTube';
import TwitterIcon from '@material-ui/icons/Twitter';
import LandingPage from "components/LandingPage/LandingPage";
const routeChange = () => {
  const randomState = uuidv4();
  window.sessionStorage.setItem("uuid", randomState);
  window.location.href = `${endpoints.signin}?redirect_uri=${encodeURIComponent(
    window.location.origin
  )}/verificaclaveunica&state=${randomState}`;
};

class SignIn extends React.Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      futuroPaP: "",
    };
  }

  componentDidMount() {
    window.sessionStorage.clear();
    this.getLandingPageData();
  }

  componentDidUpdate() {
    if (this.props.showMessage) {
      setTimeout(() => {
        this.props.hideMessage();
      }, 100);
    }
    if (this.props.authUser !== null) {
      this.props.history.push("/");
    }
  }

  getLandingPageData = () => {
    console.log("Estoy llamando a la función")
    this.setState({futuroPaP: "hola soy un test"});
  };

  render() {
    const { showMessage, loader, alertMessage } = this.props;
    const { futuroPaP } = this.state;
    return (
      <>
        <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
          <div className="app-login-main-content">
            <div className="app-logo-content d-flex align-items-center justify-content-center">
              <Link className="logo-lg" to="/" title="SEIM">
                <img
                  src={require("assets/images/logo.png")}
                  className="logophoto"
                  alt="SEIM"
                  title="SEIM"
                />
              </Link>
            </div>
            <div className="app-login-content">
              <div className="app-login-header mb-4">
                <h1>Sistema de Evaluación de Impacto en la Movilidad</h1>
              </div>
              <div className="app-login-form">
                <form>
                  <fieldset>
                    <div className="app-social-block my-1 my-sm-3">
                      Acceso de Personas Naturales o Jurídicas al SEIM
                    </div>
                    <div className="mb-12 d-flex align-items-center justify-content-between">
                      <a
                        className="btn-cu btn-m  btn-color-estandar"
                        href="#"
                        onClick={routeChange}
                        title="Este es el botón Iniciar sesión de ClaveÚnica"
                      >
                        <span className="cl-claveunica" />
                        <span className="texto">Iniciar sesión</span>
                      </a>
                    </div>
                  </fieldset>
                </form>
              </div>
            </div>
          </div>
          {loader && (
            <div className="loader-view">
              <CircularProgress />
            </div>
          )}
          {showMessage && NotificationManager.error(alertMessage)}
          <NotificationContainer />
        </div>

        <LandingPage />

      </>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { loader, alertMessage, showMessage, authUser } = auth;
  return { loader, alertMessage, showMessage, authUser };
};

export default connect(mapStateToProps, {
  userSignIn,
  hideMessage,
  showAuthLoader,
  userFacebookSignIn,
  userGoogleSignIn,
  userGithubSignIn,
  userTwitterSignIn,
})(SignIn);
