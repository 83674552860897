import React from 'react';
import {Link} from 'react-router-dom';

const Error403 = () => (
    <div className="app-wrapper page-error-container animated slideInUpTiny animation-duration-3">
        <div className="page-error-content">
            <div className="error-code mb-4">403</div>
            <h2 className="text-center fw-regular title">
                Error 403 Forbidden: Acceso no permitido
            </h2>
            <p className="text-center">
                <Link className="btn btn-primary" to="../home">Volver</Link>
            </p>
        </div>
    </div>
);

export default Error403;
