import React, {useEffect, useState} from "react";
import Grid from '@material-ui/core/Grid';
import {makeStyles} from '@material-ui/core/styles';
import {useDropzone} from 'react-dropzone';
import UploadFilesService from 'services/backend/uploadFilesService';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import CircularIndeterminate from 'components/CircularProgress/index'
import {useRecoilState} from 'recoil';
import {alertAtom, dialogAtom, messageAlertAtom, titleAlertAtom} from 'atoms/alertAtom';
import AlertHelper from 'services/helpers/alertHandler';
import AlerDialog from 'components/AlertDialog/AlertDialog';
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { ContactSupportOutlined } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    root:  {
        flexGrow: 1,
    },
    paper: {
        padding:   theme.spacing(1),
        textAlign: 'center',
        color:     theme.palette.text.secondary,
    },
}));


function UploadFiles({
                         stateUpload,
                         maxFiles = 1,
                         allowed = [{mimetype: 'application/pdf', extension: 'pdf'}],
                         multiple,
                         qtyUploadedFiles = 0,
                         uploadedFiles = []
                     })
{

    const classes = useStyles();
    const alertHelper = new AlertHelper();
    const uploadFilesService = new UploadFilesService();
    const [errorArchivos, setErrorArchivos] = useState(false);
    const [errorArchivosTexto,] = useState("¡Su archivo no cumple con el formato requerido!");
    const [dialog, setDialog] = useRecoilState(dialogAtom);
    const [alert, setCallAlert] = useRecoilState(alertAtom);
    const [titleAlert, setTitleAlert] = useRecoilState(titleAlertAtom);
    const [messageAlert, setMessageAlert] = useRecoilState(messageAlertAtom);
    const [allowedMimetype, setAllowedMimetype] = useState('image/*,application/pdf');
    const [filesListLocal, setFilesListLocal] = useState([]);
    const [filesUploaded, setFilesUploaded] = useState(uploadedFiles);
    const [showDivBotton, setShowDivBotton] = useState('block');
    const [showDivLoader, setShowDivLoader] = useState('none');
    const [qtyUploadedFilesLocal, setQtyUploadedFilesLocal] = useState(qtyUploadedFiles);
    const {getRootProps, getInputProps, open, acceptedFiles} = useDropzone({
                                                                               getFilesFromEvent: (event) => {
                                                                                   addFilesToLocalFilesList(event)
                                                                               },
                                                                               noClick:           true,
                                                                               noKeyboard:        true,
                                                                               multiple:          multiple,
                                                                               accept:            allowedMimetype,
                                                                           });

    useEffect(() => {
        let _allowed = allowed.map((fileType) => `${fileType.mimetype}, .${fileType.extension}`).join(',')
        setAllowedMimetype(_allowed)
        setFilesListLocal(acceptedFiles)
        if (filesUploaded.length > 0) {
            setFilesUploaded(uploadedFiles);
        }
    }, []);

    const onConfirm = () => {
        setCallAlert(null);
    }

    const elim = (deletedFile) => {
        let newList = filesListLocal.filter(file => file !== deletedFile)
        setFilesListLocal(newList)
    }

    const filesLocales = filesListLocal.map((file, index) => (
        <Paper variant="elevation" className="p-1 m-1" key={index}>
            <Typography color={!file.accepted ? 'error' : ''}
                        variant="caption">{file.name} - {file.size} bytes {file.accepted ? 'error' : ''} </Typography>
            <IconButton onClick={(e) => {
                elim(file)
            }} children={<DeleteForeverIcon color="error" fontSize="small"/>}/>
        </Paper>
    ));

    const filesSubidos = filesUploaded.map((file, index) => (
        <Paper variant="elevation" className="p-1 m-1" key={index} width={100}>
            <Typography variant="caption">{file.name} - {file.urlImg} </Typography>
            <IconButton onClick={(e) => {
                eliminarSubidos(file)
            }} children={<DeleteForeverIcon color="error" fontSize="small"/>}/>
        </Paper>
    ));

    useEffect(() => {
        let _files_locales_lenght = filesLocales.length || 0
        let _files_subidos_lenght = filesSubidos.length || 0
        setQtyUploadedFilesLocal(qtyUploadedFiles + _files_locales_lenght + _files_subidos_lenght)
    }, [qtyUploadedFiles, filesLocales, filesSubidos])

    const eliminarSubidos = (deleteFile) => {
        let newList = filesUploaded.filter(file => file !== deleteFile);
        setFilesUploaded(newList);
        stateUpload(newList);
    }

    const subirArchivo = () => {
        // console.log("Estoy en subir archivo");
        setShowDivBotton('none')
        setShowDivLoader('block')
        let promises = filesListLocal.map((docto) => {
            console.log("Estoy adeentro del let de promesas");
            console.log("docto es");
            console.log(docto);
            console.log("fileslistlocal es:");
            console.log(filesListLocal);
            return uploadFilesService.upload(docto)
        })
        // console.log("promesas es");
        // console.log(promises);
        Promise.all(promises).then(values => {
            console.log("Estoy en promise");
            let listSalida = []
            values.forEach(archivosalida => {
                // console.log("Estoy en el foreach");
                // console.log("archivosalida es");
                // console.log(archivosalida);
                let {uploaded, rejected, failed} = archivosalida.data
                if (failed.length > 0) {
                    // console.log("estoy en el if de failed");
                    uploaded.map((_upd) => {
                        listSalida.push({
                                            status:  'ERROR',
                                            message: _upd.error,
                                            urlImg:  _upd.id,
                                            name:    _upd.nombre,
                                        })
                    })
                }
                else if (rejected.length > 0) {
                    // console.log("estoy en el if de rejected");
                    uploaded.map((_upd) => {
                        listSalida.push({
                                            status:  'RECHAZADO',
                                            message: _upd.error,
                                            urlImg:  _upd.id,
                                            name:    _upd.nombre,
                                        })
                    })
                }
                else if (uploaded.length > 0) {
                    // console.log("estoy en el if de uploaded");
                    uploaded.map((_upd) => {
                        listSalida.push({
                                            status:  'OK',
                                            message: '',
                                            urlImg:  _upd.id,
                                            name:    _upd.nombre,
                                        })
                    })
                }
            });
            // console.log("fin de promesa")
            setFilesListLocal([]);
            setFilesUploaded([...listSalida, ...filesUploaded]);
            stateUpload(listSalida)
            setShowDivBotton('block')
            setShowDivLoader('none')
            setDialog({open: false, title: ''});
        }).catch(err => {
            // console.log("El error es: ")
            // console.log(err)
            setShowDivBotton('block')
            setShowDivLoader('none')
            setCallAlert("danger");
            setMessageAlert(`Ha ocurrido un problema de conexión`);
            setTitleAlert("¡Lo sentimos!");
        });
    }

    return (
        <>
            {alertHelper.createAlert(alert, onConfirm, titleAlert, messageAlert)}
            {<AlerDialog open={dialog.open} title={dialog.title} show/>}
            {
                <div className="">
                    <div justify="center" style={{
                        display:   showDivLoader,
                        position:  'absolute',
                        left:      '50%',
                        top:       '50%',
                        transform: 'translate(-50%, -50%)',
                        zIndex:    1000
                    }}>
                        <CircularIndeterminate/>
                    </div>
                    {errorArchivos && <p style={{display: showDivBotton, color: '#eb4034'}}>{errorArchivosTexto}</p>}
                    {filesSubidos.length === 0 ? '' : <>
                        <div className='dropzone-file-btn m-1'>
                            <p>Archivos en el servidor</p>
                            {filesSubidos}
                        </div>
                    </>}
                    {filesSubidos.length >= maxFiles ? <>Maximo de archivos</> : <>
                        <Grid container spacing={1}>
                            <Grid item xs={9}>
                                <Paper className={classes.paper}>
                                    <div {...getRootProps({className: 'dropzone-file-btn'})}>
                                        <input {...getInputProps()} />
                                        {filesSubidos.length > 0 ? <><Button
                                                                     variant="contained"
                                                                     color={qtyUploadedFilesLocal >= maxFiles ? 'default' : 'primary'}
                                                                     disabled={qtyUploadedFilesLocal >= maxFiles}
                                                                     onClick={open}>Elegir Archivos</Button></> :
                                         <><Button
                                             variant="contained"
                                             color={qtyUploadedFilesLocal >= maxFiles ? 'default' : 'primary'}
                                             disabled={qtyUploadedFilesLocal >= maxFiles}
                                             onClick={open}>Elegir Archivos</Button></>}
                                        {maxFiles === 1 ?
                                         <><p>Arrastra archivo o presiona Elegir Archivo</p></> :
                                         <><p>Arrastra tus archivos o presiona Elegir
                                             archivos {qtyUploadedFilesLocal}/{maxFiles}</p></>
                                        }
                                        <>{filesLocales}</>
                                    </div>
                                </Paper>
                            </Grid>
                            <Grid item xs={3}>
                                <Paper className={classes.paper}>
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        color={filesLocales.length !== 0 ? 'primary' : 'default'}
                                        disabled={filesLocales.length === 0 || qtyUploadedFilesLocal > maxFiles}
                                        onClick={(e) => {
                                            if (qtyUploadedFilesLocal <= maxFiles) {
                                                subirArchivo()
                                            }
                                        }}
                                        className="m-1 p-1 col-12" style={{justifyContent: 'center'}}>Subir</Button>
                                </Paper>
                            </Grid>
                        </Grid>
                    </>}
                </div>
            }
        </>
    );

    function addFilesToLocalFilesList(event) {
        // console.log("Estoy en addFilesToLocalFilesList");
        // console.log("event es");
        // console.log(event);
        let fileList = event.dataTransfer ? event.dataTransfer.files : event.target.files
        fileList = [...fileList]
        // console.log("fileList es:")
        // console.log(fileList)
        setErrorArchivos(false)
        const fileDefinitive = fileExtensionConstraint(fileSizeConstraint(fileList))
        // console.log("fileDefinitive es");
        // console.log(fileDefinitive);
        setFilesListLocal([...fileDefinitive, ...filesListLocal])
        // console.log("filesListLocal es");
        // console.log(filesListLocal);
        return filesListLocal
    }

    function fileSizeConstraint(file_list) {
        return file_list.filter(file => sizeFileIsAllowed(file))
    }

    function sizeFileIsAllowed(file) {
        // console.log("estoy en sizeFile");
        // console.log("File es");
        // console.log(file);
        // console.log("Tamaño es ");
        // console.log(file.size);
        const FIFTEEN_MB = 15728640
        if(file.size < FIFTEEN_MB){
            console.log("El tamaño del archivo es más pequeño que el limite propuesto")
        }
        else{
            console.log("El tamaño del archivo es más grande que el limite propuesto")
        }
        return file.size <= FIFTEEN_MB
    }

    function fileExtensionConstraint(file_list) {
        return file_list.filter(_file => fileExtensionIsAllowed(_file))
    }

    function fileExtensionIsAllowed(filename) {
        let ext = filename.name.split('.')
        let _ext = ext.pop().toLowerCase()
        return allowed.reduce((acc, alwd) => alwd.extension === _ext || acc, false)
    }
}

export default UploadFiles;
