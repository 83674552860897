import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";
import { alertAtom, dialogAtom, messageAlertAtom, titleAlertAtom } from "atoms/alertAtom";
import { SessionAtom } from "atoms/sessionAtom";
import AlertHelper from "services/helpers/alertHandler";
import AlertDialog from "components/AlertDialog/AlertDialog";
import InteractiveDialog from "components/InteractiveDialog/InteractiveDialog";
import { Avatar, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, List, ListItem, Typography } from "@material-ui/core";
import { format, getDigit } from "services/helpers/rutHandler";
import PropTypes from "prop-types";
import UserService from "services/backend/userService";


function DialogListLogin(props) {
    const history = useHistory();
    const alertHelper = new AlertHelper();
    const [ dialog, setDialog ] = useRecoilState(dialogAtom);
    const [ alert, setCallAlert ] = useRecoilState(alertAtom);
    const [ titleAlert, setTitleAlert ] = useRecoilState(titleAlertAtom);
    const [ messageAlert, setMessageAlert ] = useRecoilState(messageAlertAtom);
    const [ isOpenAcciones, setIsOpenAcciones ] = useState(false);
    const onCloseAcciones = () => { };
    const onConfirm = () => { setCallAlert(null); };
    const [ sessionData, setSessionData ] = useRecoilState(SessionAtom);
    const userService = new UserService();
    const { onSelected, terminated, open, onClose, empresasUsuario } = props;
    const [ openInteractiveDialog, setOpeninteractiveDialog ] = useState(false);
    const [ solicitudesLocal, setSolicitudesLocal ] = useState([]);
    const [ representacionLocal, setRepresentacionLocal ] = useState([]);
    const [ isLoading, setIsLoading ] = useState(true);
    const closeDialog = () => {
        handleClose();
        setOpeninteractiveDialog(!openInteractiveDialog);
    };
    const handleSelection = (nom, apel, rut, rel, dv) => {
        onSelected(nom, apel, rut, rel, dv);
    };
    const customFunctionDialog = () => {
        history.push(
            {
                pathname: "/app/home",
            });
        setOpeninteractiveDialog(!openInteractiveDialog);
    };
    const handleClose = () => {
        onClose();
    };
    const handleManejoSolicitud = (params) => {
        ManagementRepresentation(params);
    };
    const ManagementRepresentation = (params) => {
        let rutRepresentado = params.rut;
        let rutRepresentante = sessionData.rut;
        let tipoRelacion = params.tipo_relacion;
        let tipoRepresentacion = params.tipo_representacion;
        let accion = params.accion;
        let paramsConfig = {
            rutRepresentado, rutRepresentante, accion, tipoRelacion, tipoRepresentacion,
        };
        console.log("El valor de accion es: ");
        console.log(accion); 
        setDialog({ open: true, title: "Actualizando solicitud" });
        userService.ManejoRepresentacion(paramsConfig)
                   .then(res => {
                       setDialog({ open: false });
                       onClose();
                       if (accion === "ACEPTAR") {
                           sessionStorage.setItem("rut_seleccion", params.rut);
                           sessionStorage.setItem("dv_seleccion", params.dv);
                           sessionStorage.setItem("nombres_seleccion", params.nombres);
                           sessionStorage.setItem("apellido_paterno_seleccion", params.apellido_paterno);
                           sessionStorage.setItem("apellido_materno_seleccion", params.apellido_materno);
                           sessionStorage.setItem("tipo_relacion_seleccion", params.tipo_relacion);
                           sessionStorage.setItem("relacion_seleccion", params.relacion);
                           sessionStorage.setItem("tipo_representacion", params.tipo_representacion);
                           sessionStorage.setItem("solicitudes", JSON.stringify([]));
                           let {
                               rut_seleccion,
                               dv_seleccion,
                               nombres_seleccion,
                               apellido_paterno_seleccion,
                               apellido_materno_seleccion,
                               relacion_seleccion,
                               tipo_relacion_seleccion,
                               tipo_representacion,
                               ...other
                           } = sessionData;
                           setSessionData(
                               {
                                   ...other,
                                   rut_seleccion: params.rut.toString(),
                                   dv_seleccion: params.dv,
                                   nombres_seleccion: params.nombres,
                                   apellido_paterno_seleccion: params.apellido_paterno,
                                   apellido_materno_seleccion: params.apellido_materno,
                                   tipo_relacion_seleccion: params.tipo_relacion,
                                   relacion_seleccion: params.relacion,
                                   tipo_representacion: params.tipo_representacion,
                               });
                           onSelected(params.nombres, params.apellido_paterno, params.rut, params.relacion);
                           setCallAlert("success");
                           setTitleAlert("¡Éxito!");
                           setMessageAlert("Ha aceptado la solicitud como " + params.relacion);
                           DataUser(sessionData.rut);
                           setTimeout(() => {
                            window.location.reload(); // Recarga la página después de 2 segundos
                        }, 2000);
                       }
                       else if (accion === "RECHAZAR") {
                           setCallAlert("warning");
                           setTitleAlert("¡Atención!");
                           setMessageAlert("Ha rechazado la solicitud como " + params.relacion);
                           onClose();
                           setTimeout(() => {
                            window.location.reload(); // Recarga la página después de 2 segundos
                        }, 2000);
                       }
                   })
                   .catch(err => {
                       onClose();
                       setDialog({ open: false });
                       if (err.response) {
                           if (err.response.status === 400) {
                               setCallAlert("warning");
                               setTitleAlert("¡Atención!");
                               setMessageAlert(err.response.data);
                           }
                           else if (err.response.status === 504) {
                               setCallAlert("warning");
                               setTitleAlert("¡Atención!");
                               setMessageAlert("Los servicios de datos no se encuentran disponibles");
                           }
                           else if (err.response.status === 404) {
                               setCallAlert("warning");
                               setTitleAlert("Atención");
                               setMessageAlert("Datos no encontrados");
                           }
                           else {
                               setCallAlert("danger");
                               setTitleAlert("Lamentamos el inconveniente");
                               setMessageAlert("Hubo un error en la comunicación con el servidor");
                           }
                       }
                   });
    };
    const DataUser = (rut) => {
        userService.GetPerfil()
                   .then(response => {
                       sessionStorage.setItem("solicitudes", JSON.stringify(response.data.solicitudes));
                       sessionStorage.setItem("representacion", JSON.stringify(response.data.representacion));
                       setSolicitudesLocal(response.data.solicitudes);
                       setRepresentacionLocal(response.data.representacion);
                       let { solicitudes, representacion, ...other } = sessionData;
                       setSessionData({
                                          ...other,
                                          representacion: response.data.representacion,
                                          solicitudes: response.data.solicitudes,
                                      });
                   })
                   .catch(err => {
                   })
                   .finally(() => {
                       setIsLoading(false);
                   });
    };
    useEffect(() => {
        DataUser(sessionData.rut);
    }, []);
    return (
        <>
            {alertHelper.createAlert(alert, onConfirm, titleAlert, messageAlert)}
            {<AlertDialog open={dialog.open} title={dialog.title} show/>}
            <Dialog onClose={e => { handleClose(e); }}
                    aria-labelledby="simple-dialog-title"
                    open={open}
                    disableBackdropClick={true}
                    disableEscapeKeyDown={true}>
                <InteractiveDialog
                    id="dialogoInteractivo"
                    open={openInteractiveDialog}
                    title={"¡Cuidado!"}
                    content={"¡Si cambias de perfil perderás todos los cambios y volverás a la pantalla de inicio!"}
                    handleClose={e => closeDialog()}
                    buttonText1={"Cancelar"}
                    buttonText2={"Confirmar"}
                    showActions={true}
                    customFunction={e => customFunctionDialog()}/>
                <DialogTitle id="simple-dialog-title">Seleccione el perfil con que desea ingresar </DialogTitle>
                <div className="col-lg-12 col-md-6 col-12">
                    <h5 className="user-name">Persona Natural</h5>
                    <div className="jr-card jr-full-card">
                        <Box component="span">
                            <List>
                                <ListItem button
                                          onClick={e =>
                                          {
                                              sessionStorage.setItem("identificador", "NO");
                                              sessionStorage.setItem("rut_seleccion", sessionData.rut);
                                              sessionStorage.setItem("nombres_seleccion", sessionData.nombres);
                                              sessionStorage.setItem("apellido_paterno_seleccion", sessionData.apellido_paterno);
                                              sessionStorage.setItem("apellido_materno_seleccion", sessionData.apellido_materno);
                                              sessionStorage.setItem("tipo_relacion_seleccion", 1);
                                              sessionStorage.setItem("tipo_representacion", "PERSONA_NATURAL");
                                              sessionStorage.setItem("relacion_seleccion", "Persona Natural");
                                              let {
                                                  rut_seleccion,
                                                  dv_seleccion,
                                                  nombres_seleccion,
                                                  apellido_paterno_seleccion,
                                                  apellido_materno_seleccion,
                                                  relacion_seleccion,
                                                  tipo_relacion_seleccion,
                                                  tipo_representacion,
                                                  ...other
                                              } = sessionData;
                                              setSessionData(
                                                  {
                                                      ...other,
                                                      rut_seleccion: sessionData.rut.toString(),
                                                      dv_seleccion: sessionData.dv,
                                                      nombres_seleccion: sessionData.nombres,
                                                      apellido_paterno_seleccion: sessionData.apellido_paterno,
                                                      apellido_materno_seleccion: sessionData.apellido_materno,
                                                      relacion_seleccion: "Persona Natural",
                                                      tipo_relacion_seleccion: "1",
                                                      tipo_representacion: "PERSONA_NATURAL",
                                                  });
                                              handleSelection(sessionData.nombres, sessionData.apellido_paterno, sessionData.rut, "Persona Natural", sessionData.dv);
                                          }}>
                                    <Avatar className={`size-40 align-self-start mr-3`}>
                                        <i className="zmdi zmdi-account-o"/>
                                    </Avatar>
                                    <div className="user-detail">
                                        <h5 className="user-name">{sessionData.nombres} {sessionData.apellido_paterno}</h5>
                                        <h4 className="user-name">{format(`${sessionData.rut}-${getDigit(sessionData.rut)}`) || ""}</h4>
                                    </div>
                                </ListItem>
                            </List>
                        </Box>
                    </div>
                    {representacionLocal && representacionLocal.length > 0 &&
                     <div>
                         <h5 className="user-name">En representación de:</h5>
                         <div className="jr-card jr-full-card">
                             <Box component="span">
                                 <List>
                                     {representacionLocal ?
                                      representacionLocal.map(enter =>
                                                                  (
                                                                      enter.TIPO_REPRESENTACION === "PERSONA_JURIDICA" ?
                                                                      <ListItem
                                                                          button
                                                                          onClick={(e) =>
                                                                          {
                                                                              sessionStorage.setItem("identificador", enter.identificador);
                                                                              sessionStorage.setItem("rut_seleccion", enter.rut);
                                                                              sessionStorage.setItem("dv_seleccion", enter.dv);
                                                                              sessionStorage.setItem("nombres_seleccion", enter.razon_social);
                                                                              sessionStorage.setItem("apellido_paterno_seleccion", "");
                                                                              sessionStorage.setItem("apellido_materno_seleccion", "");
                                                                              sessionStorage.setItem("tipo_relacion_seleccion", enter.TIPO_RELACION);
                                                                              sessionStorage.setItem("tipo_representacion", enter.TIPO_REPRESENTACION);
                                                                              sessionStorage.setItem("direccion", enter.direccion);
                                                                              sessionStorage.setItem("direccion_numero", enter.direccion_numero);
                                                                              sessionStorage.setItem("direccion_depto", enter.direccion_depto);
                                                                              sessionStorage.setItem("email", enter.email);
                                                                              sessionStorage.setItem("relacion_seleccion", enter.DESC_RELACION);
                                                                              handleSelection(enter.razon_social, "", enter.rut, enter.DESC_RELACION, enter.dv);
                                                                              let {
                                                                                  rut_seleccion,
                                                                                  dv_seleccion,
                                                                                  nombres_seleccion,
                                                                                  apellido_paterno_seleccion,
                                                                                  apellido_materno_seleccion,
                                                                                  relacion_seleccion,
                                                                                  tipo_relacion_seleccion,
                                                                                  tipo_representacion,
                                                                                  direccion,
                                                                                  direccion_numero,
                                                                                  direccion_depto,
                                                                                  email,
                                                                                  ...other
                                                                              } = sessionData;
                                                                              setSessionData(
                                                                                  {
                                                                                      ...other,
                                                                                      rut_seleccion: enter.rut.toString(),
                                                                                      dv_seleccion: enter.dv,
                                                                                      nombres_seleccion: enter.razon_social,
                                                                                      apellido_paterno_seleccion: "",
                                                                                      apellido_materno_seleccion: "",
                                                                                      tipo_relacion_seleccion: enter.TIPO_RELACION,
                                                                                      relacion_seleccion: enter.DESC_RELACION,
                                                                                      tipo_representacion: enter.TIPO_REPRESENTACION,
                                                                                      direccion: enter.direccion,
                                                                                      direccion_numero: enter.direccion_numero,
                                                                                      direccion_depto: enter.direccion_depto,
                                                                                      email: enter.email,
                                                                                  });
                                                                          }}
                                                                          key={enter.rut}>
                                                                          <Avatar className={`size-40 align-self-start mr-3`}>
                                                                              <i className="zmdi zmdi-store"/>
                                                                          </Avatar>
                                                                          <div className="user-detail">
                                                                              <h5 className="user-name">{enter.razon_social}</h5>
                                                                              <h6 className="user-name"> {enter.DESC_RELACION} </h6>
                                                                              <h4 className="user-name"> {format(`${enter.rut}-${enter.dv}`) || ""} </h4>
                                                                              <span className="text-light-grey jr-fs-sm">{enter.tipo}</span>
                                                                          </div>
                                                                      </ListItem>
                                                                                                                       :
                                                                      <ListItem button onClick={e =>
                                                                      {
                                                                          sessionStorage.setItem("identificador", enter.identificador);
                                                                          sessionStorage.setItem("rut_seleccion", enter.rut);
                                                                          sessionStorage.setItem("nombres_seleccion", enter.nombres);
                                                                          sessionStorage.setItem("apellido_paterno_seleccion", enter.ap_paterno);
                                                                          sessionStorage.setItem("apellido_materno_seleccion", enter.ap_materno);
                                                                          sessionStorage.setItem("tipo_relacion_seleccion", enter.TIPO_RELACION);
                                                                          sessionStorage.setItem("relacion_seleccion", enter.DESC_RELACION);
                                                                          sessionStorage.setItem("tipo_representacion", enter.TIPO_REPRESENTACION);
                                                                          let {
                                                                              rut_seleccion,
                                                                              dv_seleccion,
                                                                              nombres_seleccion,
                                                                              apellido_paterno_seleccion,
                                                                              apellido_materno_seleccion,
                                                                              relacion_seleccion,
                                                                              tipo_relacion_seleccion,
                                                                              tipo_representacion,
                                                                              ...other
                                                                          } = sessionData;
                                                                          setSessionData(
                                                                              {
                                                                                  ...other,
                                                                                  rut_seleccion: enter.rut.toString(),
                                                                                  dv_seleccion: enter.dv,
                                                                                  nombres_seleccion: enter.nombres,
                                                                                  apellido_paterno_seleccion: enter.ap_paterno,
                                                                                  apellido_materno_seleccion: enter.ap_materno,
                                                                                  relacion_seleccion: enter.DESC_RELACION,
                                                                                  tipo_relacion_seleccion: enter.TIPO_RELACION,
                                                                                  tipo_representacion: enter.TIPO_REPRESENTACION,
                                                                              });
                                                                          handleSelection(enter.nombres, enter.ap_paterno, enter.rut, enter.DESC_RELACION, enter.dv);
                                                                      }}>
                                                                          <Avatar className={`size-40 align-self-start mr-3`}>
                                                                              <i className="zmdi zmdi-account-o"/>
                                                                          </Avatar>
                                                                          <div className="user-detail">
                                                                              <h5 className="user-name"> {enter.nombres} {enter.ap_paterno} {enter.ap_materno} </h5>
                                                                              <h6 className="user-name"> {enter.DESC_RELACION} </h6>
                                                                              <h4 className="user-name"> {format(`${enter.rut}-${enter.dv}`) || ""} </h4>
                                                                          </div>
                                                                      </ListItem>
                                                                  ),
                                      ) : <ListItem button> Cargando </ListItem>
                                     }
                                 </List>
                             </Box>
                         </div>
                     </div>
                    }
                    {solicitudesLocal && solicitudesLocal.length > 0 &&
                     <div>
                         <h5 className="user-name">Solicitudes:</h5>
                         <div className="jr-card jr-full-card">
                             <Box component="span">
                                 <List>
                                     {solicitudesLocal ? solicitudesLocal.map(enter => (
                                                                                  enter.TIPO_REPRESENTACION === "PERSONA_JURIDICA" ?
                                                                                  <ListItem
                                                                                      className="form-row col-12"
                                                                                      button
                                                                                      key={enter.rut}>
                                                                                      <div className="form-row col-12">
                                                                                          <Avatar className={`size-40 align-self-start mr-3`}>
                                                                                              <i className="zmdi zmdi-store"/>
                                                                                          </Avatar>
                                                                                          <div className="user-detail">
                                                                                              <h5 className="user-name">{enter.razon_social}</h5>
                                                                                              <h6 className="user-name"> {enter.DESC_RELACION} </h6>
                                                                                              <h4 className="user-name"> {format(`${enter.rut}-${getDigit(enter.rut)}`) || ""} </h4>
                                                                                              <span className="text-light-grey jr-fs-sm"> {enter.tipo} </span>
                                                                                          </div>
                                                                                      </div>
                                                                                      <div className="form-row  col-12">
                                                                                          <div className="col-6">
                                                                                              <Button
                                                                                                  className="btn-lg btn-block"
                                                                                                  variant="outlined"
                                                                                                  color="primary"
                                                                                                  size="medium"
                                                                                                  onClick={(e) =>
                                                                                                  {
                                                                                                      handleManejoSolicitud(
                                                                                                          {
                                                                                                              accion: "ACEPTAR",
                                                                                                              tipo: "juridico",
                                                                                                              rut: enter.rut,
                                                                                                              nombres: enter.razon_social,
                                                                                                              apellido_paterno: enter.ap_paterno || "",
                                                                                                              apellido_materno: enter.ap_paterno || "",
                                                                                                              tipo_relacion: enter.TIPO_RELACION,
                                                                                                              relacion: enter.DESC_RELACION,
                                                                                                              tipo_representacion: enter.TIPO_REPRESENTACION,
                                                                                                          });
                                                                                                  }}
                                                                                              >
                                                                                                  Aceptar
                                                                                              </Button>
                                                                                          </div>
                                                                                          <div className="col-6">
                                                                                              <Button className="btn-lg btn-block" variant="outlined" color="primary" size="medium"
                                                                                                      onClick={e =>
                                                                                                      {
                                                                                                          handleManejoSolicitud(
                                                                                                              {
                                                                                                                  accion: "RECHAZAR",
                                                                                                                  tipo: "juridico",
                                                                                                                  rut: enter.rut,
                                                                                                                  nombres: enter.razon_social,
                                                                                                                  apellido_paterno: enter.ap_paterno,
                                                                                                                  apellido_materno: enter.ap_materno,
                                                                                                                  tipo_relacion: enter.TIPO_RELACION,
                                                                                                                  relacion: enter.DESC_RELACION,
                                                                                                                  tipo_representacion: enter.TIPO_REPRESENTACION,
                                                                                                              });
                                                                                                      }}
                                                                                              >
                                                                                                  Rechazar
                                                                                              </Button>
                                                                                          </div>
                                                                                      </div>
                                                                                  </ListItem>
                                                                                                                                   :
                                                                                  <ListItem className="form-row col-12">
                                                                                      <div className="form-row col-12">
                                                                                          <Avatar className={`size-40 align-self-start mr-3`}>
                                                                                              <i className="zmdi zmdi-account-o"/>
                                                                                          </Avatar>
                                                                                          <div className="user-detail">
                                                                                              <h5 className="user-name"> {enter.nombres} {enter.ap_paterno} {enter.ap_materno} </h5>
                                                                                              <h6 className="user-name"> {enter.DESC_RELACION} </h6>
                                                                                              <h4 className="user-name"> {format(`${enter.rut}-${getDigit(enter.rut)}`) || ""} </h4>
                                                                                          </div>
                                                                                      </div>
                                                                                      <div className="form-row  col-12">
                                                                                          <div className="col-6">
                                                                                              <Button
                                                                                                  className="btn-lg btn-block"
                                                                                                  variant="outlined"
                                                                                                  color="primary"
                                                                                                  size="medium"
                                                                                                  onClick={(e) =>
                                                                                                  {
                                                                                                      handleManejoSolicitud(
                                                                                                          {
                                                                                                              accion: "ACEPTAR",
                                                                                                              tipo: "rep_natural",
                                                                                                              rut: enter.rut,
                                                                                                              nombres: enter.razon_social,
                                                                                                              apellido_paterno: enter.ap_paterno,
                                                                                                              apellido_materno: enter.ap_materno,
                                                                                                              tipo_relacion: enter.TIPO_RELACION,
                                                                                                              relacion: enter.DESC_RELACION,
                                                                                                              tipo_representacion: enter.TIPO_REPRESENTACION,
                                                                                                          });
                                                                                                  }}
                                                                                              >
                                                                                                  Aceptar
                                                                                              </Button>
                                                                                          </div>
                                                                                          <div className="col-6">
                                                                                              <Button className="btn-lg btn-block" variant="outlined" color="primary" size="medium"
                                                                                                      onClick={e =>
                                                                                                      {
                                                                                                          handleManejoSolicitud(
                                                                                                              {
                                                                                                                  accion: "RECHAZAR",
                                                                                                                  tipo: "rep_natural",
                                                                                                                  rut: enter.rut,
                                                                                                                  nombres: enter.razon_social,
                                                                                                                  apellido_paterno: enter.ap_paterno,
                                                                                                                  apellido_materno: enter.ap_materno,
                                                                                                                  tipo_relacion: enter.TIPO_RELACION,
                                                                                                                  relacion: enter.DESC_RELACION,
                                                                                                                  tipo_representacion: enter.TIPO_REPRESENTACION,
                                                                                                              });
                                                                                                      }}
                                                                                              >
                                                                                                  Rechazar
                                                                                              </Button>
                                                                                          </div>
                                                                                      </div>
                                                                                  </ListItem>
                                                                              ),
                                     ) : <ListItem button> Cargando </ListItem>
                                     }
                                 </List>
                             </Box>
                         </div>
                     </div>
                    }
                    {isLoading &&
                     <>
                         <DialogTitle className="d-flex justify-content-center">
                             Cargando información de perfil...
                         </DialogTitle>
                         <DialogContent className="d-flex justify-content-center">
                             <CircularProgress/>
                         </DialogContent>
                         <DialogContent className="d-flex justify-content-center">
                             <DialogContentText>Por favor espere...</DialogContentText>
                         </DialogContent>
                     </>
                    }
                </div>
            </Dialog>
            <Dialog
                open={isOpenAcciones}
                onClose={onCloseAcciones}
                aria-labelledby="form-dialog-title"
                maxWidth={"lg"}>
                <DialogContent>
                    <Typography variant={"subtitle1"}>{messageAlert}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        className="col-5 m-1 p-1 btn-block"
                        variant="outlined"
                        color="primary"
                        component="span"
                        onClick={(e) => {
                            setIsOpenAcciones(false);
                        }}
                    >Aceptar</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

DialogListLogin.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedValue: PropTypes.any.isRequired,
};
export default DialogListLogin;
