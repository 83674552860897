// eslint-disable-next-line no-useless-escape
export const patternNameAndDir = new RegExp(/^([0-9]|#|\/|\(|\)|\.|á|ú|í|ó|é|Á|Ú|Í|Ó|É|ñ|Ñ|-|\w|\s){0,250}$/);

export const patternEmail = new RegExp(/^([0-9]|@|\.|á|ú|í|ó|é|Á|Ú|Í|Ó|É|ñ|Ñ|-|\w|\s){0,250}$/);

export const patternEnterpriseName = new RegExp(/^([0-9]|\(|\)|\.|á|ú|í|ó|é|Á|Ú|Í|Ó|É|ñ|Ñ|-|\w|\s){0,200}$/);

export const patternProjectName = new RegExp(/^([0-9]|\(|\)|\.|á|ú|í|ó|é|Á|Ú|Í|Ó|É|ñ|Ñ|-|\w|\s){0,200}$/);

export const patternName = new RegExp(/^([^_\W0-9]|á|ú|í|ó|é|Á|Ú|Í|Ó|É|ñ|Ñ){0,100}$/);

export const patternNumber = new RegExp(/^(0|[0-9][0-9]*)$/);
export const patternDirectionNumber = new RegExp(/^([A-Z]|[0-9]|[a-z]|\w|\s|\.|-|\/|#){0,30}$/);

export const patternDescription = new RegExp(/^(;|,|\(|\)|\.|á|ú|í|ó|é|Á|Ú|Í|Ó|É|ñ|Ñ|-|\w|\s){0,800}$/);

export const newPatternDescription = new RegExp(/[\&|\.|\,|\;|\-|\_|\+|\{|\}|\[|\]|\*|\¡|\!|\?|\¿|\=|\)|\(|\/|\%|\$|\·|\#|\"|\#|\||\º|\ª|\\|A-Z|Ñ|ç|Ç|á|é|í|ó|ú|\s]{0,800}/i);
export const fullText = new RegExp(/([ ]|\t|\r|\n|\v|\f|)*$/)

export const coordenatesLogitude = new RegExp(/^[+-]?((180|((1[0-7][0-9]|[0-9]{0,2})\.[0-9]{0,6}))|(1[0-7][0-9]|[0-9]{0,2})|(180\.{1}0{0,6}))$/);
export const coordenatesLatitude = new RegExp(/^[+-]?((90|(([0-8][0-9]|[0-9]{0,1})\.[0-9]{0,6}))|([0-8][0-9]|[0-9]{0,1})|(90\.{1}0{0,6}))$/);

export const patternFloat = (digitBc = 8, digitAc = 2) => {
    /* Patrón permite coma decimal, hasta 2 digitos despues de la coma.*/
    return new RegExp(`/^[0-9]{0,${digitBc}}(,[0-9]{0,${digitBc}})?$/`);
}

export const patternInteger = (maxLength = 8) => {
    return new RegExp(`/^[0-9]{0,${maxLength}}$/g`);
}



