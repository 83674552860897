import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '../CircularProgress/index';
 
const InteractiveDialog = ({
    open, title, loaderTitle = "Procesando solicitud, por favor espere...", content, handleClose, customFunction,
    showActions = false, buttonText1 = "Cerrar", buttonText2 = "Guardar" }) => {

    const [loadOpen, setLoadOpen] = useState(false);


    return (

        <div>
            <Dialog open={open || false} onClose={handleClose} >
                <DialogTitle>
                    {!loadOpen ? title : loaderTitle}
                </DialogTitle>

                {loadOpen ?
                    <>
                        <DialogContent className="d-flex justify-content-center">
                            <CircularProgress />
                        </DialogContent>
                        <DialogContent className="d-flex justify-content-center">
                            <DialogContentText>Cargando...</DialogContentText>
                        </DialogContent>
                    </> :
                    <DialogContent>
                        <DialogContentText>
                            {content}
                        </DialogContentText>
                    </DialogContent>
                }

                {showActions && !loadOpen ? <DialogActions>
                    <Button onClick={e => handleClose(e)} color="secondary">
                        {buttonText1}
                    </Button>
                    <Button onClick={e => { customFunction(e); }} color="primary">
                        {buttonText2}
                    </Button>
                </DialogActions> : <></>}

            </Dialog>
        </div>
    )

}

export default InteractiveDialog;