import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import CustomScrollbars from 'util/CustomScrollbars';
import Button from '@material-ui/core/Button';
import { AllowedNav } from 'services/userRole/Allowed';


// noinspection EqualityComparisonWithCoercionJS

class SidenavContent extends Component {
	componentDidMount() {
		const { history } = this.props;
		const that = this;
		const pathname = `${history.location.pathname}`; // get current path
		const menuLi = document.getElementsByClassName('menu');
		for (let i = 0; i < menuLi.length; i++) {
			menuLi[i].onclick = function (event) {
				const parentLiEle = that.closest(this, 'li');
				if (menuLi[i].classList.contains('menu') && parentLiEle !== null) {
					event.stopPropagation();
					if (menuLi[i].classList.contains('open')) {
						menuLi[i].classList.remove('open', 'active');
					} else {
						menuLi[i].classList.add('open', 'active');
					}
				} else {
					for (let j = 0; j < menuLi.length; j++) {
						const parentLi = that.closest(this, 'li');
						if (menuLi[j] !== this && (parentLi === null || !parentLi.classList.contains('open'))) {
							menuLi[j].classList.remove('open');
						} else {
							if (menuLi[j].classList.contains('open')) {
								menuLi[j].classList.remove('open');
							} else {
								menuLi[j].classList.add('open');
							}
						}
					}
				}
			}
		}

		const activeLi = document.querySelector('a[href="' + pathname + '"]');// select current a element
		try {
			const activeNav = this.closest(activeLi, 'ul'); // select closest ul
			if (activeNav.classList.contains('sub-menu')) {
				this.closest(activeNav, 'li').classList.add('open');
			} else {
				this.closest(activeLi, 'li').classList.add('open');
			}
		} catch (error) {

		}
	}

	componentWillReceiveProps(nextProps) {
		const { history } = nextProps;
		const pathname = `${history.location.pathname}`;// get current path
		const activeLi = document.querySelector('a[href="' + pathname + '"]');// select current a element
		try {
			const activeNav = this.closest(activeLi, 'ul'); // select closest ul
			if (activeNav.classList.contains('sub-menu')) {
				this.closest(activeNav, 'li').classList.add('open');
			} else {
				this.closest(activeLi, 'li').classList.add('open');
			}
		} catch (error) {
		}
	}

	closest(el, selector) {
		try {
			let matchesFn;
			// find vendor prefix
			['matches', 'webkitMatchesSelector', 'mozMatchesSelector', 'msMatchesSelector', 'oMatchesSelector'].some(function (fn) {
				if (typeof document.body[fn] === 'function') {
					matchesFn = fn;
					return true;
				}
				return false;
			});
			let parent;
			// traverse parents
			while (el) {
				parent = el.parentElement;
				if (parent && parent[matchesFn](selector)) {
					return parent;
				}
				el = parent;
			}
		} catch (e) {
		}
		return null;
	}

	render() {
		return (
			<CustomScrollbars className=" scrollbar">
				<ul className="nav-menu">
					<li className="nav-header">
						Menú Principal
					</li>
					<li className="menu no-arrow">
						<NavLink to="/app/home">
							<i className="zmdi zmdi-home zmdi-hc-fw" />
							<span className="nav-text">Inicio</span>
						</NavLink>
					</li>
					<li className="menu collapse-box">
						<Button>
							<i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
							<span className="nav-text">
								Proyecto de crecimiento urbano
							</span>
						</Button>
						<ul className="sub-menu">
							<li>
								<NavLink
									onClick={(e) => {
										if (window.location.pathname === "/app/proyecto-inmobiliario/preliminares") {
											window.location.reload()
										}
									}}
									to="/app/proyecto-inmobiliario/preliminares">
									<span className="nav-text">Categorizar o Modificar Proyecto</span>
								</NavLink>
							</li>
							<li>
								<NavLink
									onClick={(e) => {
										if (window.location.pathname === "/app/proyecto-inmobiliario/proyecto-conjunto") {
											window.location.reload()
										}
									}}
									to="/app/proyecto-inmobiliario/proyecto-conjunto">
									<span className="nav-text">Declarar Proyecto de IMIV Conjunto</span>
								</NavLink>
							</li>
							<li>
								<NavLink
									onClick={(e) => {
										if (window.location.pathname === "/app/project-in-progress") {
											window.location.reload()
										}
									}}
									to="/app/project-in-progress">
									<span className="nav-text">Proyectos categorizados</span>
								</NavLink>
							</li>
							<li>
								<NavLink
									onClick={(e) => {
										if (window.location.pathname === "/app/proyecto-inmobiliario/ingresar-imiv") {
											window.location.reload()
										}
									}}
									to="/app/proyecto-inmobiliario/ingresar-imiv">
									<span className="nav-text">Ingresar Informe a Evaluación</span>
								</NavLink>
							</li>
						</ul>
					</li>
					<li className="menu no-arrow">
						<AllowedNav>
							<NavLink to="/app/registrar-aporte">
								<i className="zmdi zmdi-case zmdi-hc-fw" />
								<span className="nav-text">Aporte al espacio público</span>
							</NavLink>
						</AllowedNav>
					</li>
					<li className="menu no-arrow">
						<AllowedNav>
							<NavLink to="/app/notificaciones">
								<i className="zmdi zmdi-notifications-active zmdi-hc-fw" />
								<span className="nav-text">Notificaciones</span>
							</NavLink>
						</AllowedNav>
					</li>
					<li className="menu collapse-box">
						<Button>
							<i className="zmdi zmdi-exposure-alt zmdi-hc-fw" />
							<span className="nav-text">
								Procesos de evaluación
							</span>
						</Button>
						<ul className="sub-menu">
							<li className="menu no-arrow">
								<AllowedNav>
									<NavLink
										onClick={(e) => {
											if (window.location.pathname === "/app/evaluation-proccess") {
												window.location.reload()
											}
										}}
										to="/app/evaluation-proccess">
										<span className="nav-text">Proceso de crecimiento urbano</span>
									</NavLink>
								</AllowedNav>
							</li>
							<li className="menu no-arrow">
								<AllowedNav>
									<NavLink to="/app/evaluar-aporte">
										<span className="nav-text">Aporte al espacio público</span>
									</NavLink>
								</AllowedNav>
							</li>
						</ul>
					</li>
					{/* */}
					<li className="menu collapse-box">
						<Button>
						<i className="zmdi zmdi-city-alt zmdi-hc-fw" />
							<span className="nav-text">
								Persona Jurídica
							</span>
						</Button>
						<ul className="sub-menu">
							<li className="menu no-arrow">
								<AllowedNav>
									<NavLink
										onClick={(e) => {
											if (window.location.pathname === "/app/register-enterprise") {
												window.location.reload()
											}
										}}
										to="/app/register-enterprise">

										<span className="nav-text">Inscribir/Modificar persona jurídica</span>
									</NavLink>
								</AllowedNav>
							</li>
							<li className="menu no-arrow">
								<AllowedNav>
								<NavLink
										to="/app/observacion-persona-juridica/grid">
										<span className="nav-text">Personas jurídicas observadas</span>
									</NavLink>
								</AllowedNav>
							</li>
							<li className="menu no-arrow">
								<AllowedNav>
								<NavLink
										to="/app/buscar-persona-juridica">
										<span className="nav-text">Revisar estado</span>
									</NavLink>
								</AllowedNav>
							</li>
						</ul>
					</li>
					{/* <li className="menu no-arrow">
						<AllowedNav>
							<NavLink
								onClick={(e) => {
									if (window.location.pathname === "/app/register-enterprise") {
										window.location.reload()
									}
								}}
								to="/app/register-enterprise">
								<i className="zmdi zmdi-city-alt zmdi-hc-fw" />
								<span className="nav-text">Administrar persona jurídica</span>
							</NavLink>
						</AllowedNav>
					</li> */}

					{/* USUARIO AUTORIZADO */}
					<li className="menu no-arrow">
						<AllowedNav>
							<NavLink to="/app/register-employees">
								<i className="zmdi zmdi-account zmdi-hc-fw" />
								<span className="nav-text">Registrar usuario autorizado</span>
							</NavLink>
						</AllowedNav>
					</li>
					{/* CONSULTORES */}
					<li className="menu no-arrow">
						<NavLink to="/app/register-consultants">
							<i className="zmdi zmdi-account zmdi-hc-fw" />
							<span className="nav-text">Consultores</span>
						</NavLink>
					</li>
					{/* PREGUNTAS FRECUENTES */}
					{/* <li className="menu no-arrow">

							<NavLink
								onClick={(e) => {
									if (window.location.pathname === "/app/preguntas-frecuentes") {
										window.location.reload()
									}
								}}
								to="/app/preguntas-frecuentes">
								<i className="zmdi zmdi-city-alt zmdi-hc-fw" />
								<span className="nav-text">Preguntas Frecuentes</span>
							</NavLink>
					</li> */}
					{/* <li className="menu collapse-box">
						<Button>
						<i className="zmdi zmdi-city-alt zmdi-hc-fw" />
							<span className="nav-text">
								DEBUG
							</span>
						</Button>
						<ul className="sub-menu">
							<li className="menu no-arrow">
								<AllowedNav>
									<NavLink
										onClick={(e) => {
											if (window.location.pathname === "/app/debug-vista-proyecto-historial") {
												window.location.reload()
											}
										}}
										to="/app/debug-vista-proyecto-historial">

										<span className="nav-text">Mockup historial proyecto</span>
									</NavLink>
								</AllowedNav>
							</li>
							<li className="menu no-arrow">
								<AllowedNav>
									<NavLink
										onClick={(e) => {
											if (window.location.pathname === "/app/debug-vista-proyecto-documentos") {
												window.location.reload()
											}
										}}
										to="/app/debug-vista-proyecto-documentos">

										<span className="nav-text">Mockup documentos proyecto</span>
									</NavLink>
								</AllowedNav>
							</li>
							<li className="menu no-arrow">
								<AllowedNav>
									<NavLink
										onClick={(e) => {
											if (window.location.pathname === "/app/debug-vista-proyecto-ficha-intranet") {
												window.location.reload()
											}
										}}
										to="/app/debug-vista-proyecto-ficha-intranet">

										<span className="nav-text">Mockup ficha proyecto INTRANET</span>
									</NavLink>
								</AllowedNav>
							</li>
						</ul>
					</li> */}

					 
				</ul>
			</CustomScrollbars>
		);
	}
}

export default withRouter(SidenavContent);
