import React, {useEffect, useState} from 'react';
import LandingPageService from 'services/backend/landingPage';
//import { versionNumber }  from 'constants/VersionNumber';


const Footer = () => {
  let date = new Date();
  let year = date.getFullYear();
  const [versionNumber, setVersionNumber] = useState("2.0.0");

  const landingService = new LandingPageService()
  useEffect(() => {
    getLandingPageData();
  }, []);

  const getLandingPageData = () => {
    console.log("Estoy llamando a la función");
    landingService.GetLandingPageData().then(response => {
      if (response.data && response.data.length > 0) {
        response.data.forEach(item => {
          console.log("El parametro que veré ahora es")
          console.log(item.parametro)
          if (item.parametro === "num_version") {
            setVersionNumber(item.valor);
          }
        });
      } else {
        // Manejar el caso de datos vacíos
        console.log("No hay datos disponibles");
        setVersionNumber("Versión no disponible");
      }
    }).catch(error => {
      console.error("Error al obtener los datos:", error);
      // Manejar errores de solicitud aquí
    });
  };
  return (
    <footer className="app-footer">
      <span className="d-inline-block">Ministerio de Transportes y Telecomunicaciones - Gobierno de Chile &copy; {year} {versionNumber}</span>
    </footer>
  );
}
  ;

export default Footer;
