import React, { useState } from 'react'
import TextField from '@material-ui/core/TextField';
import { validate, format } from 'services/helpers/rutHandler';


const RutInput = ({ value, id, disabled, label, variant, helperText, helperTextError, onChange, size }) => {
    const [errorRut, setErrorRut] = useState(false);
    return <TextField
        id="outlined-error-helper-text"
        error={errorRut}
        disabled={disabled || false}
        label={label || 'Ingrese Rut'}
        variant={variant || "outlined"}
        size={size || "medium"}
        helperText={errorRut ? helperTextError || 'Rut ingresado no es válido' : helperText || "Formato xx.xxx.xxx-x"}
        className="col-12"
        value={value || ''}
        inputProps={{ maxLength: 12 }}
        onChange={e => {
            let newRut = e.target.value === '' ? '' : format(`${e.target.value}`);
            let valid = e.target.value === '' ? null : validate(newRut);
            setErrorRut(valid === null ? null : !valid);
            onChange(newRut, valid);
        }}
    />
}

export default RutInput;