
import React, { useState } from 'react'
import TextField from '@material-ui/core/TextField';


const EmailInput = ({ value, id, disabled, label, variant, helperText, helperTextError, maxLength = 999999, error, pattern, required = false, onChange,size }) => {
    const [errorInput, setErrorInput] = useState(false);
    const patt = new RegExp(pattern || /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    return <TextField
        id={`email-Input-${id}`}
        label={label || 'Correo electronico'}
        error={error || errorInput}
        disabled={disabled || false}
        variant={variant || "outlined"}
        className="col-12"
        type="string"
        size={size || "medium"}
        inputProps={{ maxLength: maxLength }}
        helperText={errorInput ? helperTextError || 'correo no válido' : helperText || ""}
        value={value || ''}
        onChange={e => {
            if ((patt.test(e.target.value) && e.target.value.length <= maxLength) || (!required && e.target.value === '')) {
                setErrorInput(false)
                onChange(e, false);
            } else {
                setErrorInput(true)
                onChange(e, true);
            }
        }}
    />

}

export default EmailInput;

