import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import asyncComponent from "util/asyncComponent";


const debugVistaProyectoDocumentos = ({ match }) => (
    <Switch>
        <Redirect exact from={`${match.url}/`} to={`${match.url}/view`}/>
        <Route path={`${match.url}/view`}
               component={asyncComponent(() => import("./debugVistaProyectoDocumentosView"))}
        />
    </Switch>
);
export default debugVistaProyectoDocumentos;
