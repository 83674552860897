import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

function CircularIndeterminate() {
  return (
    <div className="manage-margin">
      <CircularProgress/>
    </div>
  );
}

export default CircularIndeterminate;