import React, { useState, useEffect } from 'react'
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import { patternNameAndDir } from 'services/helpers/patternsRegex';

const TextInputPattern = (
    {
        id = `TextInput-Custom-pattern`,
        value = '',
        maxLength = 99999999999,
        onChange,
        label = '',
        variant = "outlined",
        validate = false,
        customValidator, //Debe retornar un booleano si es que se define.
        disabled = false,
        error,
        className = 'col-12',
        pattern,
        helperText,
        multiline = false,
        rows = 1,
        name = null,
        onBlur,
        placeholder = '',
        tooltip = 'NOTEXT'
    }
) => {

    const [errorDefault, setErrorDefault] = useState(false);
    // Recuerda completar el cuadro de texto con la información solicitada
    const [errorTextDefault, setErrorTextDefault] = useState('Recuerda llenar este campo');
    const [patt, setPatt] = useState(patternNameAndDir);
    const [totalCaracteres, setTotalCaracteres] = useState(maxLength)
    const [countCaracteres, setCountCaracteres] = useState(0)

    const calculaCaracteres = (length) => {
        setCountCaracteres(length)
    }

    const validateText = (e) => {
        return patt.test(e);
    }

    useEffect(() => {
        pattern && setPatt(pattern);
    }, [pattern])

    useEffect(() => {
        value && calculaCaracteres(value.length)
    }, [value]);

    useEffect(() => {
    }, []);

    return(
        <React.Fragment>
        {tooltip == 'NOTEXT' ? (
        <TextField
            id={id}
            value={value}
            inputProps={{ maxLength }}
            error={error !== null ? error : errorDefault}
            helperText={[maxLength !== 99999999999 ? countCaracteres + "/" + totalCaracteres : '', errorDefault ? errorTextDefault : errorTextDefault !== null ? helperText : ''].join(' ').trimRight()}
            label={label}
            variant={variant}
            disabled={disabled}
            className={className}
            placeholder={placeholder}
            onBlur={e => { onBlur && onBlur(e) }}
            rows={rows}
            multiline={multiline}
            onKeyUp={e => calculaCaracteres(e.target.value.length)}
            onChange={
                e => {
                    let valid = validateText(e.target.value);
                    if (e.target.value === '' || valid) {
                        calculaCaracteres(e.target.value.length)
                        onChange && onChange({ value: e.target.value, valid, name });
                        setErrorDefault(!valid);
                    }
                }
            }
        />
        ):(<Tooltip title={tooltip} placement="top">
            <TextField
            id={id}
            value={value}
            inputProps={{ maxLength }}
            error={error !== null ? error : errorDefault}
            helperText={[maxLength !== 99999999999 ? countCaracteres + "/" + totalCaracteres : '', errorDefault ? errorTextDefault : errorTextDefault !== null ? helperText : ''].join(' ').trimRight()}
            label={label}
            variant={variant}
            disabled={disabled}
            className={className}
            placeholder={placeholder}
            onBlur={e => { onBlur && onBlur(e) }}
            rows={rows}
            multiline={multiline}
            onKeyUp={e => calculaCaracteres(e.target.value.length)}
            onChange={
                e => {
                    let valid = validateText(e.target.value);
                    if (e.target.value === '' || valid) {
                        calculaCaracteres(e.target.value.length)
                        onChange && onChange({ value: e.target.value, valid, name });
                        setErrorDefault(!valid);
                    }
                }
            }
        />
        </Tooltip>
        )}
        </React.Fragment>
        );
        
    }

export default TextInputPattern;
