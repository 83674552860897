import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

// FDR para MTT



class Claveucl extends React.Component {
    constructor() {
        super();
        this.state = {
            render: false //Set render state to false
        }
    }

    componentDidMount() {
      
        setTimeout(function () { //Start the timer
            this.setState({ render: true }) //After 1 second, set render to true
        }.bind(this), 10000)
    }


    render() {
        
        return (
            <div>
                <CircularProgress />
            </div>
        )
    }
}



export default Claveucl;
