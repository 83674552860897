import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import "../styles/style.css";
import endpoints from "services/backend/endpoints";
import LoginService from "services/backend/login";
import Button from "@material-ui/core/Button";
import UserService from "services/backend/userService";
import { SessionAtom } from "atoms/sessionAtom";
import { useHistory } from "react-router-dom";
import AlerDialog from "components/AlertDialog/AlertDialog";

const VerificaClaveUnica = () => {
  const loginService = new LoginService();
  const userService = new UserService();
  const history = useHistory();
  const [showButtonIntentar, setShowButtonIntentar] = useState(false);
  const [showSpinner, setShowSpinner] = useState(true);
  const [mensaje, setMensaje] = useState("Por favor espere");
  const [mensajeChico, setMensajeChico] = useState(
    "Estamos validando el ingreso desde clave única..."
  );
  const [, setCode] = useState("");
  const [, setState] = useState("");
  const [, setUri] = useState("");
  const [rut, setRut] = useState(0);
  const [dv, setDV] = useState("");
  const [nombres, setNombres] = useState("");
  const [ap_paterno, setApPaterno] = useState("");
  const [ap_materno, setApMaterno] = useState("");
  const [sessionData, setSessionData] = useRecoilState(SessionAtom);
  useEffect(() => {
    let queryLocation = window.location.search.substring(1);
    ValidaInicioApp(queryLocation);
  }, []);

  function ValidaInicioApp(queryLocation) {
    let state = ExtraeVariableState(queryLocation);
    let code = ExtraeVariableCode(queryLocation);
    let uri = window.location.origin + "/verificaclaveunica";
    setCode(code);
    setState(state);
    setUri(uri);
    let uuid = window.sessionStorage.getItem("uuid");
    if (
      typeof code === "undefined" ||
      typeof state === "undefined" ||
      code === "" ||
      state === "" ||
      state !== uuid
    ) {
      setMensaje("Atención");
      setMensajeChico("Intente ingresar nuevamente con sistema de clave única");
      setShowSpinner(false);
      setShowButtonIntentar(true);
    } else {
      window.sessionStorage.removeItem("uuid");
      ObtenerToken(code, state, uri, null, null);
    }
  }

  const routeChange = () => {
    setShowButtonIntentar(false);
    history.push({
      pathname: "/signin",
    });
  };
  const redirectToHome = () => {
    window.location.href = endpoints.root;
  };
  const ObtenerToken = (code, state, uri) => {
    setShowSpinner(true);
    setShowButtonIntentar(false);
    loginService
      .GetObtenertoken({ code, state, uri })
      .then((response) => {
        const _access = response.data.data_tokenjwt.access;
        const _refresh = response.data.data_tokenjwt.refresh;
        const _token = response.data.data_access_token.id_token;
        const _rut = response.data.data_info.RolUnico.rut;
        const _dv = response.data.data_info.RolUnico.dv;
        const _nombres = response.data.data_info.nombre.nombres;
        const _ap_materno = response.data.data_info.nombre.ap_materno;
        const _ap_paterno = response.data.data_info.nombre.ap_paterno;
        sessionStorage.setItem("user_id", _access);
        sessionStorage.setItem("access", _access);
        sessionStorage.setItem("refresh", _refresh);
        sessionStorage.setItem("token", _token);
        sessionStorage.setItem("rut", _rut);
        sessionStorage.setItem("dv", _dv);
        sessionStorage.setItem("nombres", _nombres);
        sessionStorage.setItem("apellido_paterno", _ap_paterno);
        sessionStorage.setItem("apellido_materno", _ap_materno);
        sessionStorage.setItem("login_inicial", "true");
        setRut(_rut);
        setDV(_dv);
        setNombres(_nombres);
        setApPaterno(_ap_paterno);
        setApMaterno(_ap_materno);
      })
      .catch((err) => {
        setMensaje("Atención");
        setMensajeChico("Ingreso no válido, intente nuevamente");
        setShowSpinner(false);
        setShowButtonIntentar(true);
      });
  };
  useEffect(() => {
    if (!(rut === 0 || dv === "" || nombres === "" || ap_paterno === "")) {
      ObtieneDatosUsuario(rut, dv, nombres, ap_paterno, ap_materno);
    }
  }, [rut, dv, nombres, ap_paterno, ap_materno]);
  const ObtieneDatosUsuario = (
    _rut,
    _dv,
    _nombres,
    _apellido_paterno,
    _apellido_materno
  ) => {
    setShowSpinner(true);
    setShowButtonIntentar(false);
    userService
      .GetPerfil()
      .then((response) => {
        let _UsuariosRepresentados = response.data.usuarios_representados;
        let _EmpresasRepresentadas = response.data.empresas_representadas;
        let _Representacion = response.data.representacion;
        let _Solicitudes = response.data.solicitudes;
        let _Usuario = response.data.usuario;
        // solicitudes
        sessionStorage.setItem(
          "usuarios_representados",
          JSON.stringify(_UsuariosRepresentados)
        );
        sessionStorage.setItem(
          "empresas_representadas",
          JSON.stringify(_EmpresasRepresentadas)
        );
        sessionStorage.setItem(
          "representacion",
          JSON.stringify(_Representacion)
        );
        sessionStorage.setItem("solicitudes", JSON.stringify(_Solicitudes));
        sessionStorage.setItem("usuario", JSON.stringify(_Usuario));
        let {
          relacion_seleccion,
          usuarios_representados,
          empresas_representadas,
          representacion,
          ...other
        } = sessionData;
        let _relacion_seleccion = "";
        let _rut_seleccion = "";
        let _dv_seleccion = "";
        let _nombres_seleccion = "";
        let _apellido_paterno_seleccion = "";
        let _apellido_materno_seleccion = "";
        let _tipo_relacion_seleccion = "";
        let _tipo_representacion = "";
        if (_Representacion.length === 0) {
          _relacion_seleccion = "Persona Natural";
          _rut_seleccion = _rut;
          _dv_seleccion = _dv;
          _nombres_seleccion = _nombres;
          _apellido_paterno_seleccion = _apellido_paterno;
          _apellido_materno_seleccion = _apellido_materno;
          _tipo_relacion_seleccion = "1";
          _tipo_representacion = "PERSONA_NATURAL";
        }
        sessionStorage.setItem("rut_seleccion", _rut_seleccion);
        sessionStorage.setItem("dv_seleccion", _dv_seleccion);
        sessionStorage.setItem("nombres_seleccion", _nombres_seleccion);
        sessionStorage.setItem(
          "apellido_paterno_seleccion",
          _apellido_paterno_seleccion
        );
        sessionStorage.setItem(
          "apellido_materno_seleccion",
          _apellido_materno_seleccion
        );
        sessionStorage.setItem("relacion_seleccion", _relacion_seleccion);
        sessionStorage.setItem(
          "tipo_relacion_seleccion",
          _tipo_relacion_seleccion
        );
        sessionStorage.setItem(
          "tipo_representacion_seleccion",
          _tipo_relacion_seleccion
        );
        sessionStorage.setItem("tipo_representacion", _tipo_representacion);
        // tipo_relacion_seleccion
        setSessionData({
          ...other,
          relacion_seleccion: _relacion_seleccion,
          rut_seleccion: _rut_seleccion.toString(),
          dv_seleccion: _dv_seleccion,
          usuarios_representados: [..._UsuariosRepresentados],
          empresas_representadas: [..._EmpresasRepresentadas],
          representacion: [..._Representacion],
          solicitudes: [..._Solicitudes],
          usuario: _Usuario,
          tipo_representacion: _tipo_representacion,
        });
        redirectToHome();
      })
      .catch((err) => {
        setMensaje("Atención");
        setMensajeChico("Ingreso no válido, intente nuevamente");
        setShowSpinner(false);
        setShowButtonIntentar(true);
      });
  };

  function ExtraeVariableState(queryEntrante) {
    let pares = queryEntrante.split("&");
    for (let i = 0; i < pares.length; i++) {
      let pair = pares[i].split("=");
      if (pair[0] === "state") {
        return pair[1];
      }
    }
  }

  function ExtraeVariableCode(queryEntrante) {
    let pares = queryEntrante.split("&");
    for (let i = 0; i < pares.length; i++) {
      let pair = pares[i].split("=");
      if (pair[0] === "code") {
        return pair[1];
      }
    }
  }

  return (
    <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
      <div className="app-login-main-content">
        <AlerDialog
          open={showSpinner}
          title={mensaje}
          content={mensajeChico}
          spin={true}
          show
        />
        <AlerDialog
          open={showButtonIntentar}
          title={"Ocurrió un problema"}
          content={
            showButtonIntentar && (
              <Button onClick={routeChange} variant="contained" color="primary">
                Por favor intente ingresar nuevamente
              </Button>
            )
          }
          spin={false}
          show
        />
      </div>
    </div>
  );
};
export default VerificaClaveUnica;
