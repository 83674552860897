import endpoints from './endpoints';
import axiosApiInstance from './axios.interceptor'

class UploadFilesService {

    constructor() { }

    getFolioSeim(tipo_documento_seim) {
        const config = {
            params: {
                tipo_documento_seim: tipo_documento_seim
            }
        }
        return axiosApiInstance.get(endpoints.backend.get_folio_seim, config)
    }

    upload(file, folio_documento=null, tipo_documento_seim=null) {
        const formData = new FormData();
        formData.append(
            "file",
            file,
            file.name
        );
        formData.append("folio_documento", folio_documento)
        formData.append("tipo_documento_seim", tipo_documento_seim)
        return axiosApiInstance.post(endpoints.backend.uploadfile, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        });
    }

}

export default UploadFilesService;
