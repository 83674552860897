import rules from './rules';
import React from 'react';
import { useRecoilValue } from 'recoil';
import Error403 from 'components/Error/Error403';
import { SessionAtom } from 'atoms/sessionAtom';
import {
    TIPO_RELACION_TITULAR
} from 'constants/TipoRelacion';

export const AllowedNav = ({ children }) => {
    const sessionData = useRecoilValue(SessionAtom)
    if (rules[sessionData.tipo_relacion_seleccion || TIPO_RELACION_TITULAR].allowed.indexOf(children.props.to) !== -1) {
        return children;
    } else {
        return <></>
    }
}

export const AllowedRoute = ({ children, location }) => {
    const sessionData = useRecoilValue(SessionAtom)
    const tipo = Number(sessionData.tipo_relacion_seleccion || TIPO_RELACION_TITULAR)
    return rules[tipo].allowed.indexOf(location.pathname) !== -1 ? children : <Error403 />; //cambiar por no autorizado
}

