const LocalStorageService = (function () {
  let _service;
  function _getService() {
    if (!_service) {
      _service = this;
      return _service
    }
    return _service
  }
  function _setToken(access) {
    sessionStorage.setItem('access', access);
    //sessionStorage.setItem('refresh', tokenObj.refresh_token);
  }
  function _getAccessToken() {
    return sessionStorage.getItem('access');
  }
  function _getRefreshToken() {
    return sessionStorage.getItem('refresh');
  }
  function _clearToken() {
    sessionStorage.removeItem('access');
    sessionStorage.removeItem('refresh');
  }
  return {
    getService: _getService,
    setToken: _setToken,
    getAccessToken: _getAccessToken,
    getRefreshToken: _getRefreshToken,
    clearToken: _clearToken
  }
})();
export default LocalStorageService;