import React, {useEffect, useState} from 'react';
import YouTubeIcon from '@material-ui/icons/YouTube';
import LandingPageService from 'services/backend/landingPage';
import { BIG_TEXT_SIZE } from "constants/BigTextSizes";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, } from '@material-ui/core';
import Paper from "@material-ui/core/Paper";
import { alertAtom, dialogAtom, messageAlertAtom, titleAlertAtom } from "atoms/alertAtom";
import TextInputPattern from "components/TextInputPattern";
import { fullText } from "services/helpers/patternsRegex";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import RutInput from "components/RutInput/RutInput";
import EmailInput from "components/EmailInput/emailInput";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import UploadFiles from "components/UploadFiles/UploadFiles";

const LandingPage = () => {
  const [futuroPaP, setFuturoPaP] = useState("Fecha futura");
  const [versionNumber, setVersionNumber] = useState("2.0.0");
  const landingService = new LandingPageService()
  const [ modalTitle, setModalTitle ] = useState("");
  const [ modalContent, setModalContent ] = useState(null);
  const [ isOpenModal, setIsOpenModal ] = useState(false);
  const [ nombreUsuario, setNombreUsuario ] = useState("");
  const [ textoCorreo, setTextoCorreo ] = useState("");
  const [ rutUsuario, setRutUsuario ] = useState("");
  const [ emailSol, setEmailSol ] = useState("");
  const [ tipoInst, setTipoInst ] = useState("");
  const [ instListado, setInstListado ] = useState([]);
  const [ tipoTicket, setTipoTicket ] = useState("");
  const [ ticketListado, setTicketListado ] = useState([]);
  const [ identificadorProyecto, setIdentificadorProyectp ] = useState("");
  const [ asunto, setAsunto ] = useState("");
  const [ documentos, setDocumentos ] = useState([]);
  const [ archivosSubidosCorreo, setArchivosSubidosCorreo ] = useState([]);
  const [ nombreDocs, setNombreDocs] = useState([]);
  useEffect(() => {
    getLandingPageData();
    setInstListado([1, 2, 3, 4, 5])
    setTicketListado([1, 2, 3, 4, 5])
  }, []);


  const getLandingPageData = () => {
    landingService.GetLandingPageData().then(response => {
      if (response.data && response.data.length > 0) {
        response.data.forEach(item => {
          if (item.parametro === "fecha_prox_pub") {
            setFuturoPaP(item.valor);
          } else if (item.parametro === "num_version") {
            setVersionNumber(item.valor);
          }
        });
      } else {
        setFuturoPaP("Fecha futura no disponible");
        setVersionNumber("Versión no disponible");
      }
    }).catch(error => {
      console.error("Error al obtener los datos:", error);
      
    });
  };

  const handleNombreUsuarioChange = (event) => {
    setNombreUsuario(event.value); 
  };

  const handleTextoCorreoChange = (event) => {
    setTextoCorreo(event.value); 
  };

  const PopupCorreo = (item) => {
        
    setIsOpenModal(true);
  };

  const handleDialogClose = () => {
    setIsOpenModal(false);
    setModalTitle("");
    setModalContent(null);
};

  return (
    <div>
      <div
        style={{
          position: "absolute",
          bottom: "15vh", 
          left: 0,
          right: 0,
          margin: "auto",
          textAlign: "center",
        }}
      >
        Dudas o consultas <b style={{ color: "blue" }} >soporte.SEIM@mtt.gob.cl</b> | <a href="https://www.youtube.com/@seim1018"><YouTubeIcon /> </a>
      </div>

      <div
        style={{
          position: "absolute",
          bottom: "10vh",
          left: 0,
          right: 0,
          margin: "auto",
          textAlign: "center",
        }}
      >
        Fecha próxima actualización: {futuroPaP}
      </div>

      <div
        style={{
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
          margin: "auto",
          textAlign: "center",
        }}
      >
        {versionNumber} - Extranet Seim
      </div>

      <div>
                <Dialog
                    open={isOpenModal}
                    //onClose={handleClose}
                    aria-labelledby="form-dialog-title"
                    fullWidth={true}
                    maxWidth="lg" 
                    style={{ width: 1000, alignItems: "center", justifyContent: "center" }} 
                    >
                        {/* {alertAviso} */}
                    <DialogTitle id="form-dialog-title">{modalTitle}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                        <div className="d-flex col-6 col-xs-12">
                            <FormControl className="w-100 mb-2">
                                <RutInput
                                    value={rutUsuario}
                                    label="Rut de empresa (*)"
                                    onChange={value => setRutUsuario(value)}
                                    
                                />
                            </FormControl>
                        </div>
                        <TextInputPattern
                                id="nombre-proyecto"
                                label="Ingrese su nombre"
                                variant="outlined"
                                className="col-12"
                                value={nombreUsuario}
                                pattern={fullText}
                                inputProps={{ maxLength: BIG_TEXT_SIZE }}
                                maxLength={BIG_TEXT_SIZE}
                                multiline
                                rows={1}
                                InputLabelProps={{ shrink: true }}
                                onChange={handleNombreUsuarioChange}
                                disabled={false}

                            />
                            <TextInputPattern
                                id="nombre-proyecto"
                                label="Ingrese su dirección de correo institucional"
                                variant="outlined"
                                className="col-12"
                                value={nombreUsuario}
                                pattern={fullText}
                                inputProps={{ maxLength: BIG_TEXT_SIZE }}
                                maxLength={BIG_TEXT_SIZE}
                                multiline
                                rows={1}
                                InputLabelProps={{ shrink: true }}
                                onChange={handleNombreUsuarioChange}
                                disabled={false}

                            />
                            <br></br>
                            <FormControl variant="outlined" className="col-12">
                              <InputLabel id="l-tipoTramite">Tipo institucion</InputLabel>
                                <Select
                                  labelId="l-tipoTramite"
                                  id="tipoTramite"
                                  onChange={(e) => {
                                    setTipoInst(e.target.value);
                                  }}
                                  label="Tipo de trámite a realizar"
                                >
                                {instListado.map(() => <MenuItem value={instListado} children={instListado}/>)}
                              </Select>
                             </FormControl>
                            <br></br>
                            <FormControl variant="outlined" className="col-12">
                              <InputLabel id="l-tipoTramite">Tipo Ticket</InputLabel>
                                <Select
                                  labelId="l-tipoTramite"
                                  id="tipoTramite"
                                  onChange={(e) => {
                                    setTipoTicket(e.target.value);
                                  }}
                                  label="Tipo de trámite a realizar"
                                >
                                {ticketListado.map(() => <MenuItem value={ticketListado} children={ticketListado}/>)}
                              </Select>
                             </FormControl>
                             <br></br>
                            <TextInputPattern
                                id="comentario-proyecto"
                                label="Ingrese su correo"
                                variant="outlined"
                                className="col-12"
                                value={textoCorreo}
                                pattern={fullText}
                                inputProps={{ maxLength: BIG_TEXT_SIZE }}
                                maxLength={BIG_TEXT_SIZE}
                                multiline
                                rows={5}
                                InputLabelProps={{ shrink: true }}
                                onChange={handleTextoCorreoChange}
                                disabled={false}
                            />
                            <br></br>
                            <div className="row">
                              <div className="col-lg-12">
                                  <br/>
                                  <h4>Documentos adjuntos (*)</h4>
                                  {/* <h4>Acta constitutiva (*)</h4> */}
                                  <UploadFiles
                                      multiple={true}
                                      maxFiles={10}
                                      allowed={[ { mimetype: "application/pdf", extension: "pdf" } ]}
                                      uploadedFiles={archivosSubidosCorreo}
                                      allowedMultiple={true}
                                      stateUpload={(eventStatus) => {
                                          setArchivosSubidosCorreo(eventStatus);
                                          // Obtener todas las URL de los archivos y actualizar el estado con ellas
                                          const urls = eventStatus.map(file => file.urlImg);
                                          const names = eventStatus.map(file => file.name)
                                          setDocumentos(urls);
                                          setNombreDocs(names);
                                      }}/>
                              </div>
                              </div>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button > Enviar Correo </Button>
                        <Button onClick={handleDialogClose} autoFocus>Cerrar</Button>
                    </DialogActions>
                </Dialog>
      </div>
    </div>
  );
};

export default LandingPage;
