import { baseApp, pathApp } from '../constants';

const rules = {
    1/*TIPO_RELACION_TITULAR*/: {
        allowed: [
            `/${baseApp}${pathApp.home}`,
            `/${baseApp}${pathApp.projectInmo}`,
            `/${baseApp}${pathApp.notifications}`,
            `/${baseApp}${pathApp.projectInProgress}`,
            `/${baseApp}${pathApp.evalProc}`,
            `/${baseApp}${pathApp.registerEnterprise}`,
            `/${baseApp}${pathApp.registerEnterpriseForm}`,
            `/${baseApp}${pathApp.registerEmployees}`,
            `/${baseApp}${pathApp.registerEmployeesForm}`,
            `/${baseApp}${pathApp.registerConsultants}`,
            `/${baseApp}${pathApp.registrarAporte}`,
            `/${baseApp}${pathApp.evaluarAporte}`,
            `/${baseApp}${pathApp.buscarPersonaJuridica}`,
            `/${baseApp}${pathApp.buscarPersonaJuridicaForm}`,
            `/${baseApp}${pathApp.gridObservacionPersonaJuridica}`,
            `/${baseApp}${pathApp.personaJuridicaObservadaForm}`,
            `/${baseApp}${pathApp.debugVistaProyectoHistorial}`,
            `/${baseApp}${pathApp.debugVistaProyectoHistorialView}`,
            `/${baseApp}${pathApp.debugVistaProyectoDocumentos}`,
            `/${baseApp}${pathApp.debugVistaProyectoDocumentosView}`,
            `/${baseApp}${pathApp.debugVistaProyectoFichaIntranet}`,
            `/${baseApp}${pathApp.debugVistaProyectoFichaIntranetView}`,
        ]
    },
    2/*TIPO_RELACION_REPRESENTANTE_LEGAL*/: {
        allowed: [
            `/${baseApp}${pathApp.home}`,
            `/${baseApp}${pathApp.projectInmo}`,
            `/${baseApp}${pathApp.notifications}`,
            `/${baseApp}${pathApp.projectInProgress}`,
            `/${baseApp}${pathApp.evalProc}`,
            `/${baseApp}${pathApp.registerEmployees}`,
            `/${baseApp}${pathApp.registerEmployeesForm}`,
            `/${baseApp}${pathApp.registerConsultants}`,
            `/${baseApp}${pathApp.registrarAporte}`,
            `/${baseApp}${pathApp.evaluarAporte}`,
            `/${baseApp}${pathApp.buscarPersonaJuridica}`,
            `/${baseApp}${pathApp.buscarPersonaJuridicaForm}`,
            `/${baseApp}${pathApp.gridObservacionPersonaJuridica}`,
            `/${baseApp}${pathApp.personaJuridicaObservadaForm}`,
            `/${baseApp}${pathApp.debugVistaProyectoHistorial}`,
            `/${baseApp}${pathApp.debugVistaProyectoHistorialView}`,
            `/${baseApp}${pathApp.debugVistaProyectoDocumentos}`,
            `/${baseApp}${pathApp.debugVistaProyectoDocumentosView}`,
            `/${baseApp}${pathApp.debugVistaProyectoFichaIntranet}`,
            `/${baseApp}${pathApp.debugVistaProyectoFichaIntranetView}`,
        ]
    },
    3/*TIPO_RELACION_PROYECTISTA*/: {
        allowed: [
            `/${baseApp}${pathApp.home}`,
            `/${baseApp}${pathApp.projectInmo}`,
            `/${baseApp}${pathApp.notifications}`,
            `/${baseApp}${pathApp.projectInProgress}`,
            `/${baseApp}${pathApp.evalProc}`,
            `/${baseApp}${pathApp.buscarPersonaJuridica}`,
            `/${baseApp}${pathApp.buscarPersonaJuridicaForm}`,
            `/${baseApp}${pathApp.gridObservacionPersonaJuridica}`,
            `/${baseApp}${pathApp.personaJuridicaObservadaForm}`,
            `/${baseApp}${pathApp.debugVistaProyectoHistorial}`,
            `/${baseApp}${pathApp.debugVistaProyectoHistorialView}`,
            `/${baseApp}${pathApp.debugVistaProyectoDocumentos}`,
            `/${baseApp}${pathApp.debugVistaProyectoDocumentosView}`,
            `/${baseApp}${pathApp.debugVistaProyectoFichaIntranet}`,
            `/${baseApp}${pathApp.debugVistaProyectoFichaIntranetView}`,
        ]
    },
    4/*TIPO_RELACION_CONSULTOR*/: {
        allowed: [
            `/${baseApp}${pathApp.home}`,
            `/${baseApp}${pathApp.projectInmo}`,
            `/${baseApp}${pathApp.notifications}`,
            `/${baseApp}${pathApp.projectInProgress}`,
            `/${baseApp}${pathApp.evalProc}`,
            `/${baseApp}${pathApp.buscarPersonaJuridica}`,
            `/${baseApp}${pathApp.buscarPersonaJuridicaForm}`,
            `/${baseApp}${pathApp.gridObservacionPersonaJuridica}`,
            `/${baseApp}${pathApp.personaJuridicaObservadaForm}`,
            `/${baseApp}${pathApp.debugVistaProyectoHistorial}`,
            `/${baseApp}${pathApp.debugVistaProyectoHistorialView}`,
            `/${baseApp}${pathApp.debugVistaProyectoDocumentos}`,
            `/${baseApp}${pathApp.debugVistaProyectoDocumentosView}`,
            `/${baseApp}${pathApp.debugVistaProyectoFichaIntranet}`,
            `/${baseApp}${pathApp.debugVistaProyectoFichaIntranetView}`,
        ]
    },
    5/*TIPO_RELACION_REPRESENTANTE*/: {
        allowed: [
            `/${baseApp}${pathApp.home}`,
            `/${baseApp}${pathApp.projectInmo}`,
            `/${baseApp}${pathApp.notifications}`,
            `/${baseApp}${pathApp.projectInProgress}`,
            `/${baseApp}${pathApp.evalProc}`,
            `/${baseApp}${pathApp.registerEnterprise}`,
            `/${baseApp}${pathApp.registerEnterpriseForm}`,
            `/${baseApp}${pathApp.registerEmployees}`,
            `/${baseApp}${pathApp.registerEmployeesForm}`,
            `/${baseApp}${pathApp.registerConsultants}`,
            `/${baseApp}${pathApp.registrarAporte}`,
            `/${baseApp}${pathApp.evaluarAporte}`,
            `/${baseApp}${pathApp.buscarPersonaJuridica}`,
            `/${baseApp}${pathApp.buscarPersonaJuridicaForm}`,
            `/${baseApp}${pathApp.gridObservacionPersonaJuridica}`,
            `/${baseApp}${pathApp.personaJuridicaObservadaForm}`,
            `/${baseApp}${pathApp.debugVistaProyectoHistorial}`,
            `/${baseApp}${pathApp.debugVistaProyectoHistorialView}`,
            `/${baseApp}${pathApp.debugVistaProyectoDocumentos}`,
            `/${baseApp}${pathApp.debugVistaProyectoDocumentosView}`,
            `/${baseApp}${pathApp.debugVistaProyectoFichaIntranet}`,
            `/${baseApp}${pathApp.debugVistaProyectoFichaIntranetView}`,
        ]
    }
}

export default rules;
