import React from "react";
import { ConnectedRouter } from 'connected-react-router'
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import configureStore, { history } from './store';
import App from './containers/App';
export const store = configureStore();

const MainApp = () => {
	return (
		<RecoilRoot>
			<Provider store={store}>
				<ConnectedRouter history={history}>
					<Switch>
						<Route path="/" component={App} />
					</Switch>
				</ConnectedRouter>
			</Provider>
		</RecoilRoot>
	)
}

export default MainApp;