import endpoints from './endpoints';
import axios from 'axios';

class LoginService {
    constructor() {
        this.config = {}
    }

    GetObtenertoken({code, state, uri}) {
        this.config = {
            code, state, uri
        }

        return axios.post(endpoints.backend.obtenertoken, this.config);
    }
}

export default LoginService;