import {
	atom
} from 'recoil';

export const SessionAtom = atom({

	key: 'sessionAtom',

	default: {
		token: sessionStorage.getItem('token') || '',
		refresh: sessionStorage.getItem('refresh') || '',
		access: sessionStorage.getItem('access') || '',
		identificador: sessionStorage.getItem('identificador') || '',
		rut: sessionStorage.getItem('rut') || '',
		dv: sessionStorage.getItem('dv') || '',
		nombres: sessionStorage.getItem('nombres') || '',
		direccion: sessionStorage.getItem('direccion'),
		direccion_numero: sessionStorage.getItem('direccion_numero'),
		direccion_depto: sessionStorage.getItem('direccion_depto'),
		email: sessionStorage.getItem('email'),
		apellido_materno: sessionStorage.getItem('apellido_materno') || '',
		apellido_paterno: sessionStorage.getItem('apellido_paterno') || '',
		/* esto es lo que va a cambiar */
		tipo_relacion_seleccion: sessionStorage.getItem('tipo_relacion_seleccion') || '',
		// PROYECTISTA || CONSULTOR || REPRESENTANTE || PERSONA_NATURAL
		relacion_seleccion: sessionStorage.getItem('relacion_seleccion') || '',
		// RUT seleccionado|| RUN seleccion
		rut_seleccion: sessionStorage.getItem('rut_seleccion') || '',
		dv_seleccion: sessionStorage.getItem('dv_seleccion') || '',
		nombres_seleccion: sessionStorage.getItem('nombres_seleccion') || '',
		apellido_paterno_seleccion: sessionStorage.getItem('apellido_paterno_seleccion') || '',
		apellido_materno_seleccion: sessionStorage.getItem('apellido_materno_seleccion') || '',
		aporte_espacio_publico: sessionStorage.getItem('aporte_espacio_publico') || [],
		representacion: JSON.parse(sessionStorage.getItem('representacion')) || [],
		solicitudes: JSON.parse(sessionStorage.getItem('solicitudes')) || [],
		empresas_representadas: JSON.parse(sessionStorage.getItem('empresas_representadas')) || [],
		usuarios_representados: JSON.parse(sessionStorage.getItem('usuarios_representados')) || [],
		usuario: JSON.parse(sessionStorage.getItem('usuario')) || [],
		tipo_representacion: sessionStorage.getItem('tipo_representacion')
	},
});