import React from "react";
import Avatar from '@material-ui/core/Avatar'
import { connect } from 'react-redux'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { userSignOut } from 'actions/Auth';
import IntlMessages from 'util/IntlMessages';
import DialogListLogin from './DialogListLogin';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { getDigit } from 'services/helpers/rutHandler';
import AlerDialog from 'components/AlertDialog/AlertDialog'
import endpoints from 'services/backend/endpoints';

class UserInfo extends React.Component {

	state = {
		anchorEl: null,
		open: false,
		closedialog: false,
		userName: "Invitado",
		userType: "Persona Natural",
		userNumber: "",
		selectedRut: "",
		rut: '',
		nombre: '',
		apellido_pat: '',
		apellido_mat: '',
		tipo: '',
		rut_selected: '',
		nombre_selected: '',
		apellido_pat_selected: '',

		dv: '',
		nombres_seleccion: '',
		apellido_paterno_seleccion: '',
		apellido_materno_seleccion: '',
		rut_seleccion: '',
		relacion_seleccion: '',
		solicitudes: [],

		tipo_selected: '',
		empresasUsuario: null,
		solicitudesRepresentacion: null,
		showSpinner: false,
		terminated: false
	};

	handleClick = event => {
		this.setState({ open: true, anchorEl: event.currentTarget });
	};

	handleRequestClose = () => {
		this.setState({ open: false });
	};

	handleInfoRut = () => {
		this.setState({ nombre: "" });
		this.setState({ tipo: "" });
		this.setState({ closedialog: true });
	}

	OpendialogLogin = () => {
		this.setState({ closedialog: true });
	}

	shouldComponentUpdate(nextProps, nextState) {
		return this.state !== nextState
	}

	componentDidMount() {

		this.setState({ empresasUsuario: JSON.parse(sessionStorage.getItem('representacion')) });
		this.setState({ solicitudes_representacion: JSON.parse(sessionStorage.getItem('usuarios_representados')) });
		this.setState({ solicitudes: JSON.parse(sessionStorage.getItem('solicitudes')) });

		this.setState({ rut: sessionStorage.getItem('rut') })
		this.setState({ dv: sessionStorage.getItem('dv') })
		this.setState({ nombres: sessionStorage.getItem('nombres') })
		this.setState({ apellido_paterno: sessionStorage.getItem('apellido_paterno') })
		this.setState({ apellido_materno: sessionStorage.getItem('apellido_materno') })

		this.setState({ rut_seleccion: sessionStorage.getItem('rut_seleccion') })
		this.setState({ nombres_seleccion: sessionStorage.getItem('nombres_seleccion') })
		this.setState({ apellido_paterno_seleccion: sessionStorage.getItem('apellido_paterno_seleccion') })
		this.setState({ apellido_materno_seleccion: sessionStorage.getItem('apellido_materno_seleccion') })

		this.setState({ relacion_seleccion: sessionStorage.getItem('relacion_seleccion') })
		let _relacion_seleccion = sessionStorage.getItem('relacion_seleccion');
		let rut = sessionStorage.getItem('rut');

		if (_relacion_seleccion === null) {
			this.redirectToHome()
		} else if (_relacion_seleccion === '') {
			this.setState({ closedialog: true });
		}
	}

	redirectToHome() {
		window.location.href = endpoints.root;
	}

	render() {
		const { validate, clean, format } = require('rut.js');
		// se ejecuta cuando onClose es ejecutado desde el componente DialogListLogin
		const handleClose = () => {
			this.setState({ closedialog: false });
		}
		const handleSelected = () => {
			handleClose();
		};
		return (
			<>
				<AlerDialog
					open={this.state.showSpinner}
					title={'Actualizando perfil'}
					spin={true}
					show />
				<DialogListLogin
					selectedValue={this.handleInfoRut}
					open={this.state.closedialog}
					onClose={handleClose}
					onSelected={(nom, apel, rut, rel) => {
						handleSelected()
						this.setState({ nombres_seleccion: nom });
						this.setState({ apellido_paterno_seleccion: apel });
						this.setState({ rut_seleccion: rut });
						this.setState({ dv_seleccion: rut });
						this.setState({ relacion_seleccion: rel });
					}}
					terminated={this.state.terminated}
					empresasUsuario={this.state.empresasUsuario}
					solicitudes={this.state.solicitudes}
					nombreUsuario={this.state.nombres}
					apellidoMaternoUsuario={this.state.apellido_mat}
					apellidoPaternoUsuario={this.state.apellido_pat}
					rutUsuario={this.state.rut}
				/>

				{/*this.state.empresasUsuario ? '' : seteaPersonaSoloNatutal()*/}
				<Divider light />
				<div className="user-profile d-flex flex-row align-items-center">
					<Avatar alt='...' src={'https://via.placeholder.com/150x150'} className="user-avatar " />
					<div className="user-detail">
						<h2 className="user-name" onClick={this.handleClick}>
							{this.state.nombres} {this.state.apellido_paterno}
							<i className="zmdi zmdi-caret-down zmdi-hc-fw align-middle" />
						</h2>
						<h5 className="user-name"> {this.state.rut}-{this.state.dv}</h5>
						<h6 className="user-name"> {this.state.relacion_seleccion}</h6>
						{ this.state.relacion_seleccion !== "Persona Natural" &&
							<h6 style={{color: '#a1a1a1'}}> En nombre de</h6>
						}
						{ this.state.rut_seleccion !== this.state.rut  &&
							<h4 className="user-name">{this.state.nombres_seleccion} {this.state.apellido_paterno_seleccion}</h4>
						}
					</div>
					<Menu className="user-info" id="simple-menu"
						anchorEl={this.state.anchorEl}
						open={this.state.open} onClose={this.handleRequestClose}
						PaperProps={{
							style: {
								minWidth: 120,
								paddingTop: 0,
								paddingBottom: 0
							}
						}}>
						<MenuItem onClick={this.handleRequestClose}>
							<Box>
								<Card>
									<CardContent>
										<div className="user-detail" onClick={this.OpendialogLogin}>
											<h5 className="user-name">{this.state.nombres} {this.state.apellido_paterno}</h5>
											<h6 className="user-name"> {this.state.relacion_seleccion} </h6> 
											{ this.state.relacion_seleccion !== "Persona Natural" &&
												<h6 style={{color: '#a1a1a1'}}> En nombre de</h6>
											}
											<h4 className="user-name">{this.state.nombres_seleccion} {this.state.apellido_paterno_seleccion}</h4>
											<span className="jr-fs-sm">{format(`${this.state.rut_seleccion}-${getDigit(this.state.rut_seleccion)}`) || ''} </span>
										</div>
									</CardContent>
								</Card>
							</Box>
						</MenuItem>
						<MenuItem onClick={() => { this.handleRequestClose(); this.props.userSignOut() }}>
							<i className="zmdi zmdi-sign-in zmdi-hc-fw mr-2" />
							<IntlMessages id="popup.logout" />
						</MenuItem>
					</Menu>
				</div>

			</>
		);
	}
}

const mapStateToProps = ({ settings }) => {
	const { locale } = settings;
	return { locale }
};
export default connect(mapStateToProps, { userSignOut })(UserInfo);


