import endpoints from './endpoints';
import axios from 'axios';

class LandingPageService {
    constructor() {
        this.config = {}
    }

    GetLandingPageData() {
        console.log("Estoy en el servicio de landing page")
        return axios.get(endpoints.backend.obtenerLandingPage, this.config);
        //return null;
    }
}

export default LandingPageService;