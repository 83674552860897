
import endpoints from './endpoints';
import axiosApiInstance from './axios.interceptor'


class UserService {

    constructor() {
        if (!!UserService.instance) {
            return UserService.instance;
        }
        UserService.instance = this;
        this.config = {}
    }

    getParticipationType() {
        return axiosApiInstance.get(endpoints.backend.participationType);
    }

    //perfil
    GetPerfil() {
        return axiosApiInstance.get(endpoints.backend.perfil);
    }

    DatosInicialesUsuario(rut, params) {
        this.config = { 
            email: params.email,
            telefono: params.telefono,
            direccion: params.direccion,
            cod_comuna: params.cod_comuna,
            direccion_numero: params.direccion_numero,
            direccion_otro: params.direccion_otro
            } 
        return axiosApiInstance.post(`${endpoints.backend.usuario}${rut}/`, this.config);
    }

    GetDatosUsuario(rut) {
        return axiosApiInstance.get(`${endpoints.backend.usuario}${rut}/`, this.config);
    }


    GetRepresentante(rut, solo_representantes=false, tipo_relacion=null) {
        this.config.params = { 
            rut: rut, 
            solo_representantes: solo_representantes,
            tipo_relacion:tipo_relacion
        }
        return axiosApiInstance.get(endpoints.backend.representantes, this.config);
    }

    createConsultant(params) {
        return axiosApiInstance.post(endpoints.backend.createConsultant, params);
    }

    DeleteConsultant(rutRepesentado, rutCreador, tipoRepresentacion) {
        this.config.data = {
            rut_representado: rutRepesentado, //rut_selected
            rut_representante: rutCreador, //rut
            tipo_participacion: tipoRepresentacion,
        }
        return axiosApiInstance.delete(endpoints.backend.createConsultant, this.config);
    }

    ManejoRepresentacion(params) {
        this.config = {
            rut_representado: params.rutRepresentado,
            rut_representante: params.rutRepresentante,
            tipo_relacion: params.tipoRelacion,
            tipo_representacion: params.tipoRepresentacion,
            accion: params.accion
        }
        return axiosApiInstance.post(endpoints.backend.manejo_representacion, this.config);
    }

}

export default UserService;