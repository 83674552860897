import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '../CircularProgress/index';


const AlertDialog = ({ open, title, content, handldeClose, show = null, spin = true, customFunction, showButton = false }) => {

    return (
        <Dialog open={open || false} onClose={handldeClose} >
            <DialogTitle className="d-flex justify-content-center">
                {title || "Procesando solicitud, por favor espere..."}
            </DialogTitle>
            <DialogContent className="d-flex justify-content-center">
                {spin && <CircularProgress />}
            </DialogContent>
            <DialogContent className="d-flex justify-content-center">
                <DialogContentText>{content || "Cargando..."}</DialogContentText>
            </DialogContent>
        </Dialog>
    )
}

export default AlertDialog;
