import React, { useEffect } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { useRecoilState } from "recoil";
import { SessionAtom } from "atoms/sessionAtom";
import Header from "components/Header/index";
import Sidebar from "containers/SideNav/index";
import Footer from "components/Footer";
import { userSignOut } from "actions/Auth";
import { isIOS, isMobile } from "react-device-detect";
import { connect } from "react-redux";
import asyncComponent from "util/asyncComponent";
import TopNav from "components/TopNav";
import ProyectoInmobiliario from "./routes/proyecto-inmobiliario";
import EmptyComponent from "components/EmptyComponent/EmptyComponent";
import { ABOVE_THE_HEADER, BELOW_THE_HEADER, COLLAPSED_DRAWER, FIXED_DRAWER, HORIZONTAL_NAVIGATION } from "constants/ActionTypes";
import ComunasService from "services/backend/comunasService";


const App = (props) => {
    const { match, drawerType, navigationStyle, horizontalNavPosition } = props;
    const drawerStyle = drawerType.includes(FIXED_DRAWER) ? "fixed-drawer" : drawerType.includes(COLLAPSED_DRAWER) ? "collapsible-drawer" : "mini-drawer";
    if (isIOS && isMobile) {
        document.body.classList.add("ios-mobile-view-height");
    }
    else if (document.body.classList.contains("ios-mobile-view-height")) {
        document.body.classList.remove("ios-mobile-view-height");
    }
    const [ sessionData, setSessionData ] = useRecoilState(SessionAtom);
    new ComunasService();
    useEffect(() => {
        if (typeof sessionData.token === 'undefined') {
            userSignOut();
        }
        let {
            rut_seleccion,
            dv_seleccion,
            nombres_seleccion,
            apellido_paterno_seleccion,
            apellido_materno_seleccion,
            relacion_seleccion,
            tipo_relacion_seleccion,
            ...other
        } = sessionData;
        setSessionData({
                           ...other,
                           rut_seleccion: sessionStorage.getItem("rut_seleccion").toString(),
                           dv_seleccion: sessionStorage.getItem("dv_seleccion"),
                           nombres_seleccion: sessionStorage.getItem("nombres_seleccion"),
                           apellido_paterno_seleccion: sessionStorage.getItem("apellido_paterno_seleccion"),
                           apellido_materno_seleccion: sessionStorage.getItem("apellido_materno_seleccion"),
                           relacion_seleccion: sessionStorage.getItem("relacion_seleccion"),
                           tipo_relacion_seleccion: sessionStorage.getItem("tipo_relacion_seleccion"),
                           tipo_representacion: sessionStorage.getItem("tipo_representacion"),
                       });
    }, []);
    return (
        <div className={`app-container ${drawerStyle}`}>
            <Sidebar/>
            <div className="app-main-container">
                <div className={`app-header ${navigationStyle === HORIZONTAL_NAVIGATION ? "app-header-horizontal" : ""}`}>
                    {(
                     navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === ABOVE_THE_HEADER
                     ) && <TopNav styleName="app-top-header"/>}
                    <Header/>
                    {(
                     navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === BELOW_THE_HEADER
                     ) && <TopNav/>}
                </div>
                <main className="app-main-content-wrapper">
                    <div className="app-main-content">
                        <Switch>
                            <Route path={`${match.url}/home`} component={asyncComponent(() => import("./routes/Home/index"))}/>
                            <Route path={`${match.url}/notificaciones`} component={asyncComponent(() => import("./routes/SamplePage/index"))}/>
                            <Route path={`${match.url}/proyecto-inmobiliario`} component={ProyectoInmobiliario}/>
                            <Route path={`${match.url}/registrar-aporte`} component={asyncComponent(() => import("./routes/AporteEspacioPublico"))}/>
                            <Route path={`${match.url}/evaluar-aporte`} component={asyncComponent(() => import("./routes/EvaluacionAporteEspacioPublico"))}/>
                            <Route path={`${match.url}/notificaciones`} component={EmptyComponent}/>
                            <Route path={`${match.url}/evaluation-proccess`} component={asyncComponent(() => import("./routes/EvaluationProccess/index"))}/>
                            <Route path={`${match.url}/project-in-progress`} component={asyncComponent(() => import("./routes/ProjectInProgress/index"))}/>
                            <Route path={`${match.url}/register-enterprise`} component={asyncComponent(() => import("./routes/RegisterEnterprise/index"))}/>
                            <Route path={`${match.url}/register-employees`} component={asyncComponent(() => import("./routes/RegisterEmployees/index"))}/>
                            <Route path={`${match.url}/register-consultants`} component={asyncComponent(() => import("./routes/Consultant/index"))}/>
                            <Route path={`${match.url}/buscar-persona-juridica`} component={asyncComponent(() => import("./routes/buscarPersonaJuridica/index"))}/>
                            <Route path={`${match.url}/observacion-persona-juridica`} component={asyncComponent(() => import("./routes/observacionPersonaJuridica/index"))}/>
                            <Route path={`${match.url}/preguntas-frecuentes`} component={asyncComponent(() => import("./routes/preguntasFrecuentes/index"))}/>
                            <Route path={`${match.url}/debug-vista-proyecto-historial`} component={asyncComponent(() => import("./routes/debugVistaProyectoHistorial/index"))}/>
                            <Route path={`${match.url}/debug-vista-proyecto-documentos`} component={asyncComponent(() => import("./routes/debugVistaProyectoDocumentos/index"))}/>
                            <Route path={`${match.url}/debug-vista-proyecto-ficha-intranet`} component={asyncComponent(() => import("./routes/debugVistaProyectoFichaIntranet/index"))}/>
                            <Route component={asyncComponent(() => import("components/Error404"))}/>
                        </Switch>
                    </div>
                    <Footer/>
                </main>
            </div>
        </div>
    );
};
const mapStateToProps = ({ settings }) => {
    const { drawerType, navigationStyle, horizontalNavPosition } = settings;
    return { drawerType, navigationStyle, horizontalNavPosition };
};
export default withRouter(connect(mapStateToProps)(App));
